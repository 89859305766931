import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import { styled } from '@mui/system'
import Marquee from 'react-fast-marquee'
import {
  MediaCoverageBg,
  ForbesImg,
  BlockonomiImg,
  ZycryptoImg,
  CoincodexImg,
  ThenewscryptoImg,
  NewsBtcImg,
  InvestingCubeImg,
  BlockchainReporterImg,
  WalletInvestorImg,
  BitconistImg,
  ChainbitsImg,
  FinboldImg,
  NasscomImg,
  InsideBitcoinsImg,
  TechbullionImg,
  LeftLightImg,
  Publish0xImg
} from 'src/config/images'

const featuredInData = [
  { key: 0, name: 'Forbes', src: ForbesImg, height: '46px', href: 'https://www.forbes.com/sites/digital-assets/2023/10/26/traditional-banking-is-poised-to-take-bigger-stake-in-crypto-space/?sh=2a4df0613673' },
  { key: 1, name: 'Blockonomi', src: BlockonomiImg, height: '44px', href: 'https://blockonomi.com/kunji' },
  { key: 2, name: 'ZyCrypto', src: ZycryptoImg, height: '40px', href: 'https://zycrypto.com/ongoing-research-is-key-to-cryptos-growth-every-project-must-pull-its-weight/' },
  { key: 3, name: 'Coincodex', src: CoincodexImg, height: '40px', href: 'https://coincodex.com/article/32926/high-yield-crypto-trading-strategies-now-accessible-via-non-custodial-decentralized-platforms' },
  { key: 4, name: 'The News Crypto', src: ThenewscryptoImg, height: '50px', href: 'https://thenewscrypto.com/this-protocol-uses-human-asset-managers-to-minimize-crypto-trading-risk/' },
  { key: 5, name: 'News BTC', src: NewsBtcImg, height: '40px', href: 'https://www.newsbtc.com/news/company/crypto-defi-are-combining-to-make-hedge-funds-more-accessible/' },
  { key: 6, name: 'Investing Cube', src: InvestingCubeImg, height: '50px', href: 'https://www.investingcube.com/how-studying-a-web3-platforms-business-model-can-help-predict-their-long-term-success/' },
  { key: 7, name: 'Blockchain Reporter', src: BlockchainReporterImg, height: '36px', href: 'https://blockchainreporter.net/discretionary-long-short-strategies-have-only-been-available-on-cexs-not-anymore/' },
  { key: 8, name: 'Wallet Investor', src: WalletInvestorImg, height: '40px', href: 'https://walletinvestor.com/magazine/web3-platforms-could-improve-user-experience-with-insurance-from-security-breaches' },
  { key: 9, name: 'Bitcoinist', src: BitconistImg, height: '44px', href: 'https://bitcoinist.com/discretionary-long-short-crypto-trading-strategies-may-be-accessed-via-decentralized-platforms/' },
  { key: 10, name: 'Chainbits', src: ChainbitsImg, height: '40px', href: 'https://www.chainbits.com/press-releases/kunji-finance-to-launch-ido-on-poolz-finance-and-gamefi' },
  { key: 11, name: 'Finbold', src: FinboldImg, height: '40px', href: 'https://finbold.com/these-5-cryptocurrencies-dominated-this-week/' },
  { key: 12, name: 'Nasscom', src: NasscomImg, height: '30px', href: 'https://community.nasscom.in/communities/blockchain/how-defi-applications-can-benefit-using-subgraphs-data-indexing' },
  { key: 13, name: 'Inside Bitcoins', src: InsideBitcoinsImg, height: '40px', href: 'https://insidebitcoins.com/news/new-cryptocurrency-releases-listings-and-presales-today-wall-street-memes-kunji-finance-mind-matrix' },
  { key: 14, name: 'Techbullion', src: TechbullionImg, height: '50px', href: 'https://techbullion.com/okx-wallet-is-now-integrated-with-kunji-finance-beta/' },
  { key: 15, name: 'Publish0x', src: Publish0xImg, height: '40px', href: 'https://www.publish0x.com/indian-finance-and-economy/investing-in-kunji-finance-a-decentralized-asset-management-xzgovme' }
]

const FeaturedIn = () => {
  return (
    <FeaturedInContainer component='section'>
      <FeaturedInBgBox>
        <BgImg src={MediaCoverageBg} alt='bg-1-img' />
        <Bg2Img src={LeftLightImg} alt='bg-img' />
      </FeaturedInBgBox>
      <FeaturedInTitle>
        Media Coverage
      </FeaturedInTitle>
      <FeaturedInScrollContainer>
        <Marquee pauseOnHover>
          {featuredInData.map((featuredIn) => (
            <Link key={featuredIn.key} href={featuredIn.href} title={featuredIn.name} aria-label={featuredIn.name} target='_blank' rel='noreferrer noopener'>
              <FeaturedInImg src={featuredIn.src} alt={`featured-in-img-${featuredIn.key + 1}`} sx={{ height: featuredIn.height }} />
            </Link>
          ))}
        </Marquee>
      </FeaturedInScrollContainer>
    </FeaturedInContainer>
  )
}

export default FeaturedIn

const FeaturedInBgBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100vw',
  height: '100%',
  overflow: 'clip',
  pointerEvents: 'none',
  userSelect: 'none'
}))

const FeaturedInImg = styled('img')(({ theme }) => ({
  width: 'auto',
  height: '63px',
  margin: '0 20px'
}))

const FeaturedInContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  gap: 80,
  position: 'relative',
  color: '#F0F9FF',
  [theme.breakpoints.down(768)]: {
    paddingTop: '20px',
    height: '40vh',
    gap: 30
  }
}))

const FeaturedInTitle = styled(Box)(({ theme }) => ({
  fontSize: '48px',
  fontWeight: '700',
  textAlign: 'center',
  [theme.breakpoints.down(768)]: {
    fontSize: '42px'
  }
}))

const FeaturedInScrollContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#0b0b0b',
  width: '100vw',
  height: '70px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}))

const BgImg = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%) translateX(-50%)',
  left: '50%',
  zIndex: -1,
  [theme.breakpoints.down(768)]: {
    display: 'none'
  }
}))

const Bg2Img = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: -1,
  [theme.breakpoints.down(768)]: {
    display: 'none'
  }
}))
