import React from 'react'
import { styled } from '@mui/system'
import { Box } from '@mui/material'

interface PropsType {
  children: React.ReactNode
}

export const Container = (props: PropsType) => {
  return (
    <Wrapper component='nav'>
      <ContainerWrapper>{props.children}</ContainerWrapper>
    </Wrapper>
  )
}

const Wrapper = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center'
})

const ContainerWrapper = styled(Box)({
  width: '92%',
  height: '100%',
  maxWidth: '1400px',
  margin: 'auto'
})
