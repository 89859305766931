import { useEffect } from 'react';
import { ArrowOutward } from '@mui/icons-material';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';

export const Security = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);
  return (
    <SecurityContainer mb={24}>
      <SecurityTextContainer>
        <SecurityTitle>Security</SecurityTitle>
        <SecurityDescription>
          At Mimik Finance, we prioritize platform security. Our dedicated team has closely collaborated with auditors
          and consultants to create a highly secure and reliable protocol. Security researchers will soon be eligible
          for a bug bounty, incentivizing the discovery of any undisclosed vulnerabilities.
        </SecurityDescription>
      </SecurityTextContainer>
      <AuditReportContainer>
        <AuditReportTitle>Audit Report</AuditReportTitle>
        <AuditReportDescription>
          Mimik Finance has been reviewed and audited by UNSNARL and P2P Audit
        </AuditReportDescription>
        <AuditReportCardContainer>
          <a
            href="https://docs.kunji.finance/kunjifinance/audit-report"
            style={{ textDecoration: 'none' }}
            target="_blank"
            rel="noreferrer noopenner"
          >
            <AuditReportCard>
              Mimik Finance Audit by UNSNARL <ArrowOutward fontSize="large" />
            </AuditReportCard>
          </a>
          <a
            href="https://docs.kunji.finance/kunjifinance/audit-report"
            style={{ textDecoration: 'none' }}
            target="_blank"
            rel="noreferrer noopenner"
          >
            <AuditReportCard>
              Mimik Finance Audit by P2P AUDIT <ArrowOutward fontSize="large" />
            </AuditReportCard>
          </a>
        </AuditReportCardContainer>
      </AuditReportContainer>
    </SecurityContainer>
  );
};

const SecurityContainer = styled(Box)(({ theme }) => ({
  paddingTop: '100px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '40px'
}));

const SecurityTextContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column'
}));

const SecurityTitle = styled(Box)(({ theme }) => ({
  fontSize: '54px',
  color: '#FFF',
  fontWeight: '600',
  textAlign: 'center',
  [theme.breakpoints.down(768)]: {
    fontSize: '42px'
  }
}));

const SecurityDescription = styled(Box)(({ theme }) => ({
  fontSize: '18px',
  color: '#98A2B3',
  maxWidth: '800px',
  textAlign: 'center'
}));

const AuditReportContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '15px'
}));

const AuditReportTitle = styled(Box)(({ theme }) => ({
  fontSize: '26px',
  color: '#FFC529',
  fontWeight: '600'
}));

const AuditReportDescription = styled(Box)(({ theme }) => ({
  fontSize: '18px',
  color: '#98A2B3',
  textAlign: 'center'
}));

const AuditReportCardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px'
}));

const AuditReportCard = styled(Box)(({ theme }) => ({
  fontSize: '28px',
  color: '#FFFFFF',
  borderRadius: '15px',
  backgroundColor: 'rgba(168, 168, 168, 0.2)',
  padding: '10px 45px',
  display: 'flex',
  alignItems: 'center',
  fontWeight: '600',
  transition: 'all 0.6s ease',
  '&:hover': {
    transform: 'scale(1.05)'
  },
  gap: '16px',
  [theme.breakpoints.down(640)]: {
    fontSize: '20px',
    padding: '8px 20px'
  }
}));
