import { useEffect } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';

export const Service = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);
  return (
    <>
      <ServiceContainer mb={24}>
        <ServiceTitle>Service Agreement</ServiceTitle>
        <ServiceContent>
          <strong>
            Last Updated on: August 4th, 2023
            <br />
            <br />
            PLEASE GO THROUGH THIS SERVICE AGREEMENT (THIS&nbsp;“SERVICE AGREEMENT”) PRIOR TO ACCESSING MIMIK FINANCE OR
            AVAILING SERVICES (DEFINED BELOW) USING MIMIK FINANCE. BY REGISTERING, ACCESSING OR OTHERWISE CONTINUING TO
            ACCESS AND USE MIMIK FINANCE TO AVAIL THE SERVICES, THE USER HEREBY IRREVOCABLY AND UNCONDITIONALLY AGREES
            TO COMPLY WITH, ABIDE BY AND BE BOUND BY ALL THESE OBLIGATIONS AS STIPULATED IN THIS SERVICE AGREEMENT,
            WHICH TOGETHER READ WITH THE PRIVACY POLICY AVAILABLE AT&nbsp;
          </strong>
          <a href="http://www.mimik.finance/" target="_blank" rel="noreferrer noopenner">
            <strong>www.mimik.finance</strong>
          </a>
          <strong>
            AND ANY OTHER VARIATIONS OF THE LINKS AVAILABLE ON THE MIMIK FINANCE (“PRIVACY POLICY”); AND ANY OTHER
            APPLICABLE POLICIES, TERMS OF USE FOR MIMIK FINANCE (COLLECTIVELY REFERRED AS&nbsp;“TERMS AND CONDITIONS”)
            GOVERN YOUR USAGE OF MIMIK FINANCE. THIS SERVICE AGREEMENT CONSTITUTES A LEGALLY BINDING AGREEMENT BETWEEN
            THE USER AND FOUNDATION WHILE AVAILING THE SERVICES THROUGH MIMIK FINANCE AND THE USER MAY BE SUBJECT TO ANY
            POSTED GUIDELINES OR RULES APPLICABLE TO MIMIK FINANCE. ALL SUCH GUIDELINES OR RULES ARE HERBY INCORPORATED
            BY REFERENCE INTO THIS SERVICE AGREEMENT. BY USING OR ACCESSING MIMIK FINANCE, THE USER DE-FACTO AGREES TO
            THE FOLLOWING SERVICE AGREEMENT. THE USER ALSO REPRESENTS THAT IT HAS READ AND UNDERSTOOD ALL THE PROVISIONS
            OF THIS SERVICE AGREEMENT. THIS SERVICE AGREEMENT SHALL GOVERN THE FOUNDATION’S RELATIONSHIP WITH THE USER
            IN RELATION TO THE USAGE OF MIMIK FINANCE AND AVAILING THE SERVICES PROVIDED THROUGH MIMIK FINANCE.THE
            FOUNDATION AND THE USER SHALL HEREINAFTER BE SINGULARLY REFERRED TO AS A&nbsp;“PARTY”&nbsp;AND COLLECTIVELY
            AS THE&nbsp;“PARTIES”.This electronic record is generated by a computer system and does not require any
            physical or digital signatures.THE USER ACKNOWLEDGES THAT INVESTING IN CRYPTO ASSETS, CRYPTOCURRENCIES AND
            OTHER DIGITAL ASSETS (COLLECTIVELY&nbsp;“DIGITAL ASSETS”) INVOLVES A HIGH DEGREE OF RISK. DIGITAL ASSETS ARE
            SUBJECT TO CONSTANT AND FREQUENT FLUCTUATIONS IN VALUE, AND THE VALUE OF YOUR DIGITAL ASSETS MAY INCREASE OR
            DECREASE AT ANY TIME. ACCORDINGLY, THE USER MAY SUFFER A COMPLETE LOSS OF THE FUNDS. THE USER ACKNOWLEDGES
            THAT THE USER IS SOLELY RESPONSIBLE FOR DETERMINING THE NATURE, SUITABILITY, AND APPROPRIATENESS OF THESE
            INVESTMENT RISKS.IF YOU DO NOT AGREE TO BE BOUND BY ALL TERMS AND CONDITIONS CAPTURED IN THIS SERVICE
            AGREEMENT, PLEASE DO NOT USE MIMIK FINANCE. WHEREAS, The Foundation, operates the decentralised finance
            protocol and platform hosted at&nbsp;
          </strong>
          <a href="http://www.mimik.finance" target="_blank" rel="noreferrer noopenner">
            <strong>www.mimik.finance</strong>
          </a>
          <strong>
            , which allows the Users (defined below) to invest in Digital Assets (defined below) by signing up with DAMs
            (defined below) and contributing to a Digital Asset Strategy (defined below) actively managed by such DAM.
            The User has represented that it is at least eighteen (18) years of age or of legal age in the applicable
            territory to be bound contractually by this Service Agreement, in its territory of residence and has
            authorized the DAM to trade in Digital Assets and actively manage the Digital Asset Strategies. Based on the
            representation made by the User and compliance with Mimik Finance Terms and Conditions available at{' '}
          </strong>
          <a href="http://www.mimik.finance" target="_blank" rel="noreferrer noopenner">
            <strong>www.mimik.finance</strong>
          </a>
          <strong>
            , the User is allowed to access the Services, through Mimik Finance.
            <br />
            <br />
            DEFINITIONS AND INTERPRETATION:
            <br />
          </strong>
          Word shall have the meanings given to them in this Agreement, including without limitation as set out below:
          <br />
          <strong>
            ‍<br />
            Applicable Law:
            <br />
          </strong>
          Means any and all applicable laws, statutes, orders, rules, treaties, decree, regulations, directives, data
          protection laws, other rules made under or to be made under any statute, or guidelines, whether local,
          national, international or otherwise existing from time to time, together with any other similar instrument
          having a legal effect in the relevant circumstances;
          <br />
          <strong>
            <br />
            Digital Assets:
            <br />
          </strong>
          Digital Means Cryptocurrencies, Crypto Assets, Non-Fungible Tokens, other Assets Digital Assets are
          collectively known as Digital Assets;
          <br />
          <strong>
            <br />
            Digital Asset Manager (“DAM”):
            <br />
          </strong>
          Shall mean the entity having expertise in trading, investment and management of Digital Assets, trading in
          Digital Assets via Mimik Finance and having the capability to manage the Digital Asset Strategies;
          <br />
          <strong>
            <br />
            Digital Asset Strategie(s) (“DAS”):
            <br />
          </strong>
          means such portfolios of Digital Assets that are actively managed by the DAM to reflect different investment
          goals and risk profiles, as determined by the DAM in its sole discretion.
          <br />
          <strong>
            <br />
            User:
            <br />
          </strong>
          means the user of Mimik Finance, who avails the Services offered by Mimik Finance, the User avails the
          Services and invests in the DAS which are actively managed by the DAMs.
          <br />
          <strong>
            <br />
            Foundation Materials:
            <br />
          </strong>
          means, with respect to Mimik Finance, collectively, software, literary works, other works of authorship,
          specifications, designs, analyses, processes, methodologies, concepts, inventions, know-how, programs, program
          listings, programming tools, documentation, reports, drawings and work product, whether tangible or intangible
          and shall include Mimik Finance;
          <br />
          <strong>
            ‍<br />
            Confidential Information:
            <br />
          </strong>
          all information provided by the disclosing party to the receiving party, whether oral or written, technical or
          commercial know-how (including all specifications, inventions, processes, initiatives, drawings and designs,
          disclosed in writing, on disc, orally or by inspection of documents or pursuant to discussions between the
          parties) to the receiving party and relating to the either party's business, finance or technology, know-how,
          Intellectual Property Rights, including Mimik Finance, Services, User data and information relating to
          management, financial, marketing, technical and other arrangements, either Party's dealings and methods of
          dealings with existing and prospective Users, proprietary information and such other information or operations
          of any subsidiary, person, firm, or organization associated with that Party where the information is: a.
          identified or not identified as confidential at the time of disclosure; or b. ought reasonably to be
          considered confidential given the nature of the information or the circumstances of disclosure;
          <br />
          <strong>
            <br />
            Service Fees:
            <br />
          </strong>
          Means the fees deducted by Mimik Finance for facilitating the Services;
          <br />
          <strong>
            <br />
            Mimik Finance:
            <br />
          </strong>
          Shall mean the Foundation’s Digital Asset management platform, hosted at www.mimik.finance to provide services
          to the users.
          <br />
          <strong>
            <br />
            Services:
            <br />
          </strong>
          Means the Digital Asset management and management services provided services through Mimik Finance to the
          Users, through the DAM’s management of the DAS for the benefit of the Users.
          <br />
          <strong>
            <br />
            DAS Description:
            <br />
          </strong>
          shall mean the investment strategies of the DAS, including the objective of investment, risk profiles, time
          horizon, DAM profile and experience, level of activity regarding DAS management and the DAM Fees charged by
          the DAM for the investment;
          <br />
          <strong>
            <br />
            Term:
            <br />
          </strong>
          shall have the meaning given to it under Clause 13;
          <br />
          <strong>
            <br />
            Foundation:
            <br />
          </strong>
          The Kunji Dao Foundation <br />
          <strong>
            <br />
            User Account:
            <br />
          </strong>
          Shall mean the User's wallet created or linked to Mimik Finance to avail the Services;
          <br />
          <strong>
            <br />
            Returns:
            <br />
          </strong>
          Shall mean the returns earned or lost on the investments, arising out of the Digital Assets investing and
          trading carried out by the DAM and shall be a percentage of the invested amount.
          <br />
          <strong>
            <br />
            2. DIGITAL ASSET STRATEGIES <br />
          </strong>
          2.1 Mimik Finance enables the Users to invest in special DAS that are managed by the DAMs. The User may choose
          to invest as per a DAS, basis the DAS Description. <br />
          2.2 User wallet will consist of Digital Assets that the User, from time to-time, invests as per &nbsp;the DAS.
          The User represents that it is and will be the owner of all Digital Assets it places or will place in the User
          wallet.
          <br />
          2.3 The DAS will include only such range of Digital Assets that the DAM has a reasonable basis to believe is
          freely tradable.
          <strong>
            <br />
            <br />
            3. SERVICES:
            <br />
          </strong>
          3.1 The User warrants that it will enable the DAM to trade the Digital Assets freely, in accordance with the
          DAS Description.
          <br />
          3.2 User may also re-allocate its &nbsp;Digital Assets at any time.
          <br />
          3.3 The DAM will actively trade and manage the DAS in accordance with the DAS Description, as described on
          Mimik Finance. A DAM’s investment management may result in the addition of one or more Digital Assets, the
          removal of one or more Digital Assets, or changes to the relative holdings of each &nbsp;Digital Asset within
          a DAS. <br />
          3.4 User may withdraw or revoke their allocation from any DAS if they do not wish to continue investing
          through the applicable DAS and reallocate to other DAS, in their sole discretion or entirely liquidate the
          Digital Assets in accordance with the terms of this Service Agreement.
          <br />
          3.5 In the event, Mimik Finance suspects or discovers any unusual trading activities, Mimik Finance reserves
          the right to delay execution of trades, to determine the validity of the trade or allocation request.
          <br />
          <strong>
            <br />
            4. AUTHORISING SMART CONTRACTS FOR TRADE REPLICATION AS PER DAS:
            <br />
          </strong>
          4.1 Once the User opts to invest through one or more DAS, the DAMs will trade in their own vaults and User’s
          the Digital Assets in User Wallet will through smart contracts replicate the trading activities undertaken by
          the DAM. The DAM will invest, trade and rebalance the Digital Assets in the DAS actively and in accordance
          with the DAS Description. <br />
          4.2 By agreeing to the terms of this Agreement, the Usergrants the DAM and Mimik Finance a limited power of
          attorney to exercise anyand all power and discretion to replicate trades &nbsp;in the Users’ Wallet in
          accordance with the DAS Description.
          <br />
          4.3 The DAM will never have access to the User Wallet or directly control Digital Assets in the User Wallets.
          The DAM manages the Digital Assets in wallets or vaults owned by the DAM asper the DAS, once User subscribes
          to a DAS, thereafter, Mimik Finance enabled smartcontracts will replicate trades undertaken by the DAM. The
          limited power of attorney isobtained for the limited purpose allowing the replication of the trades in the
          User Wallet asper the respective DAS managed by the DAM.
          <br />
          <strong>
            <br />
            5. USER INFORMATION:
            <br />
          </strong>
          5.1 The User may view their allocations across DASand specific investments held within each DAS at any time
          through the UserWallet dashboard. Users can monitor their investments in real time and changetheir DAS
          allocations as desired.
          <br />
          <strong>
            <br />
            6. VOTING RIGHTS:
            <br />
          </strong>
          6.1 Due to the limitations of Mimik Finance, the Usermay not be able to receive the benefits of the governance
          or voting protocolsof the Digital Assets they hold in their User Wallet by opting to invest with aDAS. Mimik
          Finance’s infrastructure may not support this capability and makesno promise of doing so in the future
          <br />
          6.2 Users may not benefit from any airdrops, staking, lending or other functionalities that may be available
          to other Users that hold the Digital Assets directly.
          <br />
          <strong>
            <br />
            7. WITHDRAWALS:
            <br />
          </strong>
          7.1 The User's withdrawal request will typically be processed within a reasonable number of days, subject to
          the third-party service providers such as trading exchanges, Digital Asset liquidation partners’ etc. ability
          to process the request within that time period.
          <br />
          <strong>
            <br />
            8. PROCEEDINGS AGAINST ISSUERS:
            <br />
          </strong>
          8.1. The Foundation will not take any action or render any advice on behalf of the User with respect to
          Digital Assets that are formerly, presently or in the future held in the Wallet, or the issuers thereof, which
          were, are or become the subject of any legal proceedings (including without limitation, bankruptcy and
          shareholder class action lawsuits and any claims arising therefrom).
          <br />
          <strong>
            <br />
            9. SELECTION OF EXCHANGES OR THIRD PARTY INTEGRATIONS:
            <br />
          </strong>
          9.1 Digital Assets are purchased through third-party exchanges. Mimik Finance as per its decentralized
          governance selects and recommends any brokers, exchanges, or vaults based on a number of factors,including,
          but not limited to, ease of administration, quality of execution, commission rates, and pre-existing
          agreements. The DAM would generally seek to minimize the total price (taking into account applicable exchange
          fees) for each transaction. In certain cases, the DAM may have little or no choice as to which exchange to
          execute a transaction on, because a Digital Asset is only available for trading on one or a small number of
          exchanges. This could lead to higher costs associated with purchases or sales of Digital Assets. The User will
          be liable for all the fees to the third-party service providers for trading and wallet services for the
          Digital Assets, which will be charged directly to the User's Wallet
          <br />
          <strong>
            <br />
            10. CUSTODY:
            <br />
          </strong>
          10.1 User will hold and own their Digital Assets withthird party service providers such as digital asset
          wallets, accounts ontrading exchanges, in a User Wallet opened by the Users.
          <br />‍
          <strong>
            <br />
            11. ACCURACY OF INFORMATION:
            <br />
          </strong>
          11.1. The User Wallet dashboard or Mimik Finance interfaces will provide estimated values of Digital Assets
          and DAS currently held in the User Wallet. The pricing will be pulled directly from various exchanges and
          generally from exchanges where the trades are placed. There is no guarantee that these prices will always be
          up to date or that they will reflect the true value of the Digital Assets. Mimik Finance and DAM will not be
          liable to independently verify the accuracy of the prices on the individual exchanges.
          <br />
          11.2 In general, although Mimik Finance will use commercially reasonable efforts to provide accurate and
          timely information on Mimik Finance, Mimik Finance may not always be entirely accurate, complete or current
          and may also include technical inaccuracies or typographical errors.
          <br />
          11.3 In an effort to continue to provide as complete and accurate information as possible, information may be
          changed or updated from time to time without notice, including without limitation information regarding the
          Terms and Conditions and offerings.
          <br />
          11.4 Links to third-party materials (including without limitation websites) may be provided as a convenience
          but are not controlled by the Foundation. The User acknowledges and agrees that the Foundation is not
          responsible for any aspect of the information, content, or services contained in any third-party materials or
          on any third-party sites accessible on or linked to by Mimik Finance, including links to any exchanges and
          neither the Foundation or the DAM nor any of its affiliates is liable for any type of loss or damage
          associated with information provided by a third party.
          <br />
          <strong>
            <br />
            12. FEES; EXPENSES:
            <br />
          </strong>
          12. 1For the Services availed by the User, the User agrees that the DAM may charge the DAM Fees as computed in
          accordance with the DAS Description, which is incorporated by reference and made a part of this Service
          Agreement. The DAM Fees may change and in such an event, the DAM will notify the User by updating the DAS
          Description. We encourage you to frequently check the DAS Description to be notified of any changes to the DAM
          Fees and other associated terms.
          <br />
          11.2. In general, although Mimik Finance will use commercially reasonable efforts to provide accurate and
          timely information on Mimik Finance, Mimik Finance may not always be entirely accurate, complete or current
          and may also include technical inaccuracies or typographical errors.
          <br />‍
          <strong>
            <br />
            13. DISCLAIMER:
            <br />‍<br />
          </strong>
          13.1 INVESTMENTS IN DIGITAL ASSETS ARE HIGHLY SPECULATIVE. THE USER IS SOLELY RESPONSIBLE FOR EVALUATING THE
          MERITS AND RISKS ASSOCIATED WITH THE USE OF ANY INFORMATION, MATERIALS, CONTENT, USER CONTENT, OR THIRD-PARTY
          CONTENT PROVIDED THROUGH THE SERVICE BEFORE MAKING ANY DECISIONS BASED ON SUCH CONTENT. AS A USER OF THE
          SERVICE, THE USER IS RESPONSIBLE FOR DETERMINING WHETHER ANY CONTENT, PRODUCT, SERVICE, DIGITAL ASSET,
          SECURITY, OR OTHER FINANCIAL INSTRUMENT MENTIONED ON MIMIK FINANCE, SERVICE IS SUITABLE FOR THE USERS
          FINANCIAL PURPOSES. PAST PERFORMANCE DATA, PERFORMANCE GRAPHS OR OTHER HISTORICAL INFORMATION ARE NOT A
          GUARANTEE OF FUTURE PERFORMANCE AND SHOULD NOT BE CONSTRUED AS INDICATIVE OF FUTURE RESULTS. THE USER SHOULD
          CONSIDER THE USERS INDIVIDUAL INVESTMENT OBJECTIVES AND RISK TOLERANCE BEFORE INVESTING. THE CONTENT PRESENTED
          ON THE SERVICE IS NOT INTENDED TO PROVIDE THE USER OR ANYONE ELSE WITH INVESTMENT, LEGAL, TAX, INSURANCE OR
          ANY OTHER KIND OF PROFESSIONAL ADVICE. THE USER SHOULD CONSULT WITH A FINANCIAL, TAX OR LEGAL PROFESSIONAL FOR
          ADVICE REGARDING THE SPECIFIC FINANCIAL, TAX OR LEGAL POSITIONS AND CIRCUMSTANCES.
          <br />
          13.2 THE USER ALSO ACKNOWLEDGES AND AGREE THAT THE FOUNDATIONAND THE USER’S ABILITY TO USE THE SERVICES MAY BE
          DETRIMENTALLY IMPACTED BYREGULATORY ACTION OR CHANGES IN REGULATIONS APPLICABLE TO DIGITAL ASSETS. YOUAGREE
          THAT WE MAY DISCLOSE WALLET INFORMATION IF WE BELIEVE THAT IT ISREASONABLY NECESSARY TO COMPLY WITH A LAW,
          REGULATION, LEGAL PROCESS, ORGOVERNMENTAL REQUEST.
          <br />
          13.3 MIMIK FINANCE AND ALL FOUNDATION MATERIALS AND ALL CONTENT AVAILABLE THROUGH THE SERVICES ARE PROVIDED
          “AS IS” AND ON AN “AS AVAILABLE” BASIS, WITHOUT WARRANTY OR CONDITION OF ANY KIND, EITHER EXPRESS OR IMPLIED.
          TO THE EXTENT ALLOWED BY LAW, THE FOUNDATION DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
          RELATING TO MIMIK FINANCE AND ALL FOUNDATION MATERIALS AND CONTENT AND SERVICES MADE AVAILABLE THROUGH MIMIK
          FINANCE, INCLUDING: (I) ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
          QUIET ENJOYMENT, OR NON-INFRINGEMENT; AND (II) ANY WARRANTY ARISING OUT OF COURSE OF DEALING, USAGE, OR TRADE.
          <br />
          13.4 FOUNDATION DOES NOT WARRANT THAT MIMIK FINANCE OR ANY PORTION OF THE SERVICES, OR ANY FOUNDATION
          MATERIALS OR CONTENT OFFERED THROUGH MIMIK FINANCE, WILL BE UNINTERRUPTED, SECURE, OR FREE OF ERRORS, VIRUSES,
          OR OTHER HARMFUL COMPONENTS, AND DOES NOT WARRANT THAT ANY OF THOSE ISSUES WILL BE CORRECTED. YOU ASSUME ALL
          RISK FOR ANY DAMAGE THAT MAY RESULT FROM YOUR ACCESS TO, USE OR MISUSE OF, OR INABILITY TO USE MIMIK FINANCE
          OR SERVICES, YOUR DEALINGS WITH ANY PARTNER OR OTHER USER, AND ANY FOUNDATION MATERIALS OR ALL CONTENT MADE
          AVAILABLE THROUGH MIMIK FINANCE. YOU UNDERSTAND AND AGREE THAT YOU USE MIMIK FINANCE, AND ACCESS THE SERVICES,
          DOWNLOAD, OR OTHERWISE OBTAIN FOUNDATION MATERIALS OR CONTENT THROUGH MIMIK FINANCE AND ANY ASSOCIATED SITES,
          AT YOUR OWN DISCRETION AND RISK, AND THAT YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE, INCLUDING TO YOUR
          COMPUTER SYSTEM OR MOBILE DEVICE USED IN CONNECTION WITH MIMIK FINANCE, OR THE LOSS OF DATA THAT RESULTS FROM
          THE USE OF MIMIK FINANCE OR THE DOWNLOAD OR USE OF THE MATERIAL OR CONTENT OR SERVICES. SOME JURISDICTIONS MAY
          PROHIBIT A DISCLAIMER OF WARRANTIES AND YOU MAY HAVE OTHER RIGHTS THAT VARY FROM JURISDICTION TO JURISDICTION.
          TO THE EXTENT THAT WE MAY NOT DISCLAIM ANY IMPLIED WARRANTY, THE SCOPE AND DURATION OF SUCH WARRANTY WILL BE
          THE MINIMUM PERMITTED BY LAW.
          <br />
          13.5 FURTHER, THE USER UNDERSTANDs THAT SMART CONTRACT TRANSACTIONSAUTOMATICALLY EXECUTE AND SETTLE, AND THAT
          BLOCKCHAIN-BASED TRANSACTIONS AREIRREVERSIBLE WHEN CONFIRMED. THE USER ACKNOWLEDGES AND ACCEPTS THAT THECOST
          AND SPEED OF TRANSACTING WITH CRYPTOGRAPHIC AND BLOCKCHAIN-BASEDSYSTEMS SUCH ARE VARIABLE AND MAY INCREASE
          DRAMATICALLY AT ANY TIME. FURTHER MIMIK FINANCE BEING A DECENTRALISED FINANCE OPERATES ON A BLOCKCHAINPROTOCOL.
          THE PROTOCOL IS PROVIDED "AS IS", AT YOUR OWN RISK, AND WITHOUTWARRANTIES OF ANY KIND. ALTHOUGH THE FOUNDATION
          CONTRIBUTED TO THE INITIALCODE FOR THE PROTOCOL, THE FOUNDATION DOES NOT PROVIDE, OWN, OR CONTROL THEPROTOCOL,
          WHICH IS RUN AUTONOMOUSLY BY SMART CONTRACTS DEPLOYED ONBLOCKCHAIN. UPGRADES AND MODIFICATIONS TO THE PROTOCOL
          ARE GENERALLYMANAGED IN A COMMUNITY-DRIVEN WAY BY HOLDERS OF THE MIMIK FINANCEGOVERNANCE TOKEN. NO DEVELOPER
          OR ENTITY INVOLVED IN CREATING THE PROTOCOLWILL BE LIABLE FOR ANY CLAIMS OR DAMAGES WHATSOEVER ASSOCIATED WITH
          USER’SUSE, INABILITY TO USE, OR USER’S INTERACTION WITH OTHER USERS OF, THE PROTOCOL,INCLUDING ANY DIRECT,
          INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE ORCONSEQUENTIAL DAMAGES, OR LOSS OF PROFITS,
          CRYPTOCURRENCIES, TOKENS, DIGITALASSETS OR ANYTHING ELSE OF VALUE.
          <br />
          <strong>
            <br />
            14. REPRESENTATIONS AND WARRANTIES OF USER:
            <br />
          </strong>
          14.1 The User, whether a natural person or corporate entity, represents, warrants, and agrees that throughout
          the term of this Agreement:
          <br />
          a. The User's execution, delivery and performance of this Service Agreement does not violate or conflict with
          any agreement or obligation to which the User is a party or by which the User or its property is bound,
          whether arising by contract, operation of law or otherwise;
          <br />
          b. No governmental authorization, approvals, consents or filings are required in connection with the
          execution, delivery or performance of this Service Agreement by the User;
          <br />
          c. The User is responsible for independently examining and understanding the tax, legal and accounting
          consequences related to the User's wallet and is paying specific taxes under Applicable Laws;
          <br />
          d. Amounts contributed by the User to the wallet were not and are not directly or indirectly derived from
          activities that shall not contravene Applicable Laws or international laws and regulations, including, without
          limitation, anti-money laundering (“AML”) laws and regulations.
          <br />
          e. The User further represents and warrants to the Foundation that the proceeds from the wallet have not been
          used, is not being used and will not be used to finance any activities that may contravene any Applicable Laws
          and regulations;
          <br />
          f. The User acknowledges and agrees that the Foundation, in complying with anti-money laundering statutes,
          regulations and goals, may file activity reports or any other information with governmental and law
          enforcement agencies that identify transactions and activities that such Foundation or their agents reasonably
          determine to be suspicious, or is otherwise required by law, and hereby absolves and holds harmless such
          persons or entities for such actions and disclosures;
          <br />
          g. The User acknowledges that the Foundation may be prohibited by law from disclosing to third parties,
          including the User, any filing or the substance of any reports provided to the government entities;
          <br />
          h. The User acknowledges that Mimik Finance may require further identification or documentation of the User in
          order to comply with Applicable Laws and regulations and agrees to provide promptly such further
          identification or documentation upon request by Mimik Finance, provided that all such information of the Users
          shall be retained in accordance with Mimik Finance’s Privacy Policy;
          <br />
          i. The User understands that the representations, warranties, agreements, undertakings and acknowledgements
          made by the User in this Service Agreement will be relied upon by the Foundation for its compliance with
          various Applicable Laws;
          <br />
          j. The User represents and warrants that the compensation terms of this Service Agreement represent an
          “arms-length” arrangement and the User is satisfied that it has received adequate disclosure from the
          Foundation to enable it to understand and evaluate the compensation and other terms of this Agreement and the
          associated risks;
          <br />
          k. The User acknowledges that neither the Foundation nor, the DAM or any affiliate independently verify
          information regarding the Digital Assets;
          <br />
          l. the User acknowledges that the Foundation's decision to take certain actions, including limiting access to,
          suspending, or closing an User's wallet, may be based on confidential criteria that are essential to the
          Foundation’s risk management and security protocols, and agrees that the Foundation is under no obligation to
          disclose the details of its risk management and security procedures to the User; and
          <br />
          m. All information provided by the User is current, true, correct and complete and may be relied upon by the
          Foundation and its affiliates in evaluating User eligibility and determining whether to accept a particular
          User. The foregoing representations and warranties shall be continuing during the term of this Service
          Agreement, and if at any time during such term any event occurs which would make any of the foregoing
          representations and warranties untrue or inaccurate in any material respect, the User will promptly notify the
          Foundation in writing of such event and of any resulting untruths or inaccuracies within 30 days of said event
          or change.
          <br />
          14.2 The User understands that to the fullest extent permitted by law, any notices, updates, disclosures,
          forms, privacy statements, reports, or other communications (collectively, “<strong>Communications</strong>
          ”), including annual, quarterly, and other updates, may be delivered through electronic means, such as through
          the User Wallet dashboard or Communications official messaging channels including but not limited to the
          Discord server or Mimik Finance interfaces or by email, by the Foundation or DAM or, any of its affiliates
          without any charge by Mimik Finance to the User. The User hereby consents to electronic delivery as described
          in the preceding sentence. This consent will be effective immediately and will remain in effect unless and
          until the User revokes it. The User understands that the User's revocation of this consent may constitute a
          request for Termination of this Service Agreement.
          <br />
          14.3 All communications will be sent to the User Wallet or through official Communication channels or via
          email if the User provides the contact information. The User is responsible for maintaining valid
          Communication channels and software and hardware to receive, read and send Communications. Regardless of
          whether the User receives any communication from the Foundation, the User agrees to check the User Wallet
          dashboard or Mimik Finance Communication channels regularly for up-to-date information and to avoid missing
          time-sensitive information.
          <br />
          14.4 The User acknowledges that Communication channels may not be secure and may contain computer viruses or
          other defects, may not be accurately replicated on other systems or may be intercepted, deleted or interfered
          with, with or without the knowledge of the sender or the intended recipient. The User also acknowledges that a
          Communication from the Foundation, DAM, any of its affiliates or any of its third-party service providers, may
          be accessed by recipients other than the User, may be interfered with, may contain computer viruses or other
          defects and may not be successfully replicated on other systems. The Foundation is not responsible for
          computer viruses or related problems associated with the User's use of Mimik Finance, the User Wallet
          dashboard, Communication channels or any other online system.
          <br />
          14.5&nbsp;The User agrees to provide the Foundation, any of its affiliates, or any of its third-party service
          providers, including without limitation the DAM, with such further information, authorizations and
          documentation as the Foundation may require from time to time to carry out its obligations under this Service
          Agreement or to avoid violations of any provisions of any applicable laws, and shall notify the Foundation
          promptly in writing of any change in the information so furnished.
          <br />
          14.6 The User acknowledges that the Foundation or the DA are not responsible for legal compliance of Digital
          Asset issuers, Digital Asset exchanges, or any other third party.
          <br />
          14.7 The User acknowledges that the Digital Assets available for investment on Mimik Finance are in many cases
          not regulated and consequently information regarding the Digital Assets may be limited.
          <br />‍
          <strong>
            <br />
            15. LIMITATION OF LIABILITY; INDEMNIFICATION:
            <br />‍<br />
          </strong>
          15.1 Mimik Finance, DAM, affiliates of Mimik Finance, respective members, partners, directors, officers,
          employees, and legal representatives (e.g., executors, guardians, and trustees) of any of them(including
          persons formerly serving in such capacities) shall not be liable for any expenses, losses, damages,
          liabilities, demands, charges or claims of any kind or nature whatsoever (collectively, “Losses”) relating to
          this service agreement. In addition, it is possible that the User may experience a computer equipment failure,
          loss of internet access, viruses, or other events that may impair access to Mimik Finance. The Foundation and
          its representatives are not responsible to any User for Losses in such a case. In addition, Mimik Finance,
          DAM, affiliates of Mimik Finance, respective members, partners, directors, officers, employees, and legal
          representatives (e.g., executors, guardians, and trustees) of any of them (including persons formerly serving
          in such capacities) shall not be liable for any consequential, indirect, special or punitive damages.
          <br />
          15.2 The DAM gives no warranty as to the performance or profitability of its recommendations and directed
          investments, nor any guarantee that the investment objectives, expectations or targets described in this
          Service Agreement will be achieved, including, without limitation, any risk control, risk management or return
          objectives, expectations or targets. The User may suffer the loss of principal, and returns, if any, may
          fluctuate. The value of Wallet investments may be affected by a variety of factors, including, but not limited
          to, economic and political developments, government regulations, judicial interpretations, interest rates and
          issuer-specific events, market conditions, sector positioning and other factors.
          <br />
          15.3&nbsp;The User agrees to indemnify and hold harmless Mimik Finance, its affiliates, respective members,
          partners, directors, officers, employees and legal representatives (e.g., executors, guardians and trustees)
          of any of them (including persons formerly serving in such capacities) against any and all losses,
          liabilities, claims, damages, and expenses whatsoever (including all expenses incurred in investigating,
          preparing or defending against any claim whatsoever) arising out of or based upon (i) any false representation
          or warranty made by the User, or breach or failure by the User to comply with any covenant or agreement made
          by the User, in this Service Agreement or in any other document furnished by the User to any of the foregoing
          in connection with this transaction or future transactions, or(ii) any action for Applicable Laws violations
          instituted by the User that is finally resolved by judgment against the User.
          <br />
          15.4 The User also agrees to indemnify each Indemnified Party for any and all costs, fees, and expenses
          (including legal fees and disbursements) in connection with any damages resulting from the User
          misrepresentation or misstatement contained herein, or the assertion of the User'slack of proper authorization
          from the beneficial owner to enter into, or perform the obligations under, this Service Agreement.
          <br />
          15.5&nbsp;The User agrees to indemnify and hold harmless each Indemnified Party from and against any tax,
          interest, additions to tax, penalties, attorneys' and accountants' fees, and disbursements, together with
          interest on the foregoing amounts at a rate determined by the Foundation, computed from the date of payment
          through the date of reimbursement, arising from the failure to withhold and pay over to the taxing authority,
          as required by Applicable Law, with respect to the income or gains allocated to or amounts distributed to the
          User with respect to Digital Assets.
          <br />
          15.6 The reimbursement, indemnity, and contribution obligations of the User under this Service Agreement shall
          be in addition to any liability that the User may otherwise have, and shall be binding upon and inure to the
          benefit of any successors, assigns, heirs and personal representatives of the Indemnified Parties.
          <br />
          15.7 The DAM may rely on legal representations of the issuer of the Digital Asset in making this determination
          and will have no liability to the User for this determination.
          <br />
          15.8 &nbsp;Finance, DAM, its affiliates, respective members, partners, directors, officers, employees, and
          legal representatives(e.g., executors, guardians and trustees) of any of them (including persons formerly
          serving in such capacities) will be entitled to rely upon, and will not incur any liability for relying upon,
          any notice, request, certificate, consent, statement, instrument, document or other writing believed by them
          to be genuine and to have been signed or sent by the proper person, and will not incur any liability for
          relying on any such statement.
          <br />
          <strong>
            <br />
            16. DISPUTE RESOLUTION:
            <br />
          </strong>
          This Agreement shall be construed and governed by and in accordance with the laws of Panama. The Parties shall
          resolve any difference or dispute arising out of this Agreement by way of negotiations. The Dispute Resolution
          process will be as per Mimik Finance Terms and Conditions available and agreed in by the User.
          <br />
          <br />
          <strong>17. ASSIGNMENT AND DELEGATION</strong>
          <br />
          The User shall not assign its rights and obligations under the Service Agreement. The Foundation may assign
          its rights and obligations under the Service Agreement, to any person or entity and may delegate or
          subcontract its duties. Any unauthorized assignment of rights and obligations under the Service Agreement is
          void.
          <strong>
            <br />
            <br />
            18. INTELLECTUAL PROPERTY RIGHTS:
            <br />
          </strong>
          18.1 Any and all ownership rights to Mimik Finance, the Services, documentation, enhancements, and branding
          thereof, including intellectual property rights such as copyrights, trademarks, service marks and patents
          therein are the sole and exclusive property of the Foundation. This Service Agreement does not grant the User,
          any rights, title and interest in and to Mimik Finance, the Services, documentation, its contents, and
          branding thereof including the Foundation’s intellectual property rights, except where expressly and
          unequivocally licensed herein. Any rights not expressly and unequivocally granted to the User are reserved by
          the Foundation.
          <br />
          18.2 From time to time, the User may provide feedback, suggestions, requirements or recommendations
          (“Feedback”) regarding Mimik Finance or the Services. The User hereby assigns to the Foundation, all rights,
          title and interests to such Feedback and an exclusive right to create any developments based on such Feedback.
          <br />
          <strong>
            ‍<br />
            19. CONFIDENTIAL INFORMATION:
            <br />
          </strong>
          19.1 “Confidential Information”&nbsp;shall mean information disclosed by one Party to the other and which
          includes, without limitation the Software, Documentation, Enhancements, Fixes, financial, business, technical
          and marketing information, business plans, methods, processes, inventions, techniques, designs, data,
          know-how, ideas, concepts, strategies, trade secrets and the Services and such other information. Confidential
          Information does not include information which: (a) is in the public domain without disclosure by the Party
          and without breach of any agreement or confidentiality obligation; (b) was known to the Party at the time such
          disclosure was made, or becomes known to the Party without breach of any confidentiality agreement; (c) is
          independently developed by either Party without violating any confidentiality obligations stated herein or
          access or use of the either Party’s Confidential Information, as evidenced by written records.
          <br />
          19.2 The receiving Party shall only use the Confidential Information for the purpose, for which it is
          disclosed. Furthermore, the receiving Party shall keep Confidential Information and data received from the
          disclosing Party, in strict confidence and shall not disclose it to any third Parties, except to a limited
          group of receiving Party’s directors, officers, agents, authorized representatives on a need to-know basis.
          <br />
          19.3 Upon request by the disclosing Party, the receiving Party shall immediately return to the disclosing
          Party, all Confidential Information disclosed by the disclosing Party and all copies thereof. All such
          information shall be and shall remain the sole property of the disclosing Party.
          <br />
          19.4 The confidentiality obligations stated herein shall survive for a period of five (5) years from the date
          of termination or expiration of the Agreement.
          <br />
          19.5 The receiving Party agrees that any violation of the confidentiality obligations will cause irreparable
          injury to the disclosing Party, entitling the disclosing Party to obtain injunctive relief in addition to all
          legal remedies.
          <br />
          <strong>
            <br />
            20. DURATION AND TERMINATION:
            <br />
          </strong>
          20.1 This Service Agreement shall continue in full force and effect until it is terminated by User through
          Mimik Finance or at any time by Mimik Finance. For the avoidance of doubt, either Party may terminate this
          Service Agreement effective immediately upon written notice in the event that the other Party is in material
          breach of any provision of this Service Agreement. Mimik Finance may terminate the Service Agreement or
          suspend the Service Agreement at any time if necessary for compliance with the law, if any third-party service
          providers, are unable to support the User wallet, or if the User conducts any actions designed to circumvent
          Mimik Finance’s processes or controls.
          <br />
          20.2 The User may terminate the Services through the dashboard. The User will not be charged for cancelling
          its User, although the User will be required to pay any outstanding DAM Fees in accordance with the DAS
          Description. The User authorizes the Foundation to cancel or suspend any pending transaction at the time of
          cancellation. The User is solely responsible for withdrawing all balances in the User wallet.
          <br />
          20.3 The Sections which by their very nature are intended to survive termination shall survive the termination
          of this Service Agreement.
          <br />
          <strong>
            <br />
            21. NOTICES:
            <br />
          </strong>
          All notices and other communications required or permitted to be given under the provisions of the Agreement
          shall be in writing (which term includes electronic mail or email) in English language and shall be deemed to
          be received upon the earlier of: (i) delivery, if by hand; or (ii) upon receipt, if sent by mail (followed by
          a registered mail, return receipt requested, postage prepaid) or by an internationally recognized courier
          service or (iii) addressed to the intended recipient at his/her email address, to the following persons at the
          following addresses or email addresses, or to such other persons at such other addresses or email addresses as
          any Party may request by notice in writing to the other Party to the Agreement. All such notices to Foundation
          shall be addressed to:
          <br />
          Name: Kunji DAO <br />
          Foundation Email:
          <a href="mailto:support@mimik.finance" target="_blank" rel="noreferrer noopenner">
            support@mimik.finance
          </a>{' '}
          &nbsp;
          <br />
          <strong>
            <br />
            22. WAIVER:
            <br />
          </strong>
          Failure to exercise, or any delay in exercising, any right or remedy provided under the Agreement shall not
          constitute a waiver of that or any other right or remedy, nor shall it preclude or restrict any further
          exercise of that or any other right or remedy. No single or partial exercise of any right or remedy provided
          under the Agreement or by law shall preclude or restrict the further exercise of that or any other right or
          remedy.
          <br />
          <strong>
            <br />
            23. INDEPENDENT CONTRACTOR:
            <br />
          </strong>
          The Parties are independent contractors and none of their personnel or sub-contractors are agents,
          representatives or employees of any other Party. No Party owes a fiduciary duty to any other Party. No Party
          shall have the authority to bind or make any representations on behalf of any other Party, and no Party shall
          hold itself out as such or knowingly permit another to rely on such belief.
          <br />
          <strong>
            <br />
            24. FORCE MAJEURE:
            <br />
          </strong>
          Exceptfor the obligation of the User to pay the Fees, in any event or combination ofevents or circumstances
          beyond the control of a Party which cannot by theexercise of reasonable diligence, or despite the adoption of
          reasonable precautionand/or alternative measures be prevented, or caused to be prevented, and whichmaterially
          and adversely affects a Party’s ability to perform obligations underthe Service Agreement, including but not
          limited to:
          <br />
          22.1 Acts of God i.e. fire, drought, flood, earthquake, and other disasters;
          <br />
          23.2.&nbsp;Pandemics, explosions or accidents;
          <br />
          24.3.&nbsp;Government policy changes;
          <br />
          24.4&nbsp;Cyber incidents perpetrated by third parties;
          <br />
          24.5.&nbsp;Major unforeseen collapses in the industry due fraudulent or negligent activities by third-party
          services providers;
          <br />
          24.6.&nbsp;Quarantine situations, governmental orders,lockdowns, strikes or lockouts;
          <br />
          24.7.&nbsp;&nbsp;Any change in law; or
          <br />
          24.8.&nbsp;Any event or circumstances analogous to the foregoing, the Parties will not hold each other liable
          for their performance of its obligations as set out in the Service Agreement. Either Party shall have a right
          to suspend or terminate the Agreement in case such force majeure conditions persist for a period exceeding
          sixty (60) days.
          <br />
          <strong>
            <br />
            25. ENTIRE AGREEMENT:
            <br />
          </strong>
          This Service Agreement constitutes the entire agreement between the Parties and supersede any prior
          understanding or representation of any kind preceding the date of the Service Agreement, and may not be
          amended, supplemented, varied or otherwise changed, except in writing. There are no other promises,
          conditions, understandings or other agreements, whether oral or written, relating to the subject matter of the
          Service Agreement. Each Party acknowledges that, in agreeing to the Service Agreement, it has not relied on,
          and shall have no right or remedy in respect of, any statement, representation, assurance or warranty, other
          than as expressly set out in the Service Agreement. This Agreement may be modified by the Foundation, at any
          time, in its sole discretion.
          <br />
          <strong>
            <br />
            26. SEVERABILITY:
            <br />
          </strong>
          If any provisions of the Service Agreement are determined to be invalid, illegal or unenforceable in any
          respect, including because of the duration thereof, the area covered thereby, or the types of activities
          restricted thereby, by a court of competent jurisdiction (a) the validity, legality or enforceability of the
          remaining provisions contained herein shall not, in any way be affected or impaired thereby, and (b) the court
          of competent jurisdiction making such determination shall have the power to reduce the duration and/or area of
          such provisions or types of activities restricted and/or to delete specific words or phrases and in its
          reduced form, such provision shall then be enforceable. The Parties may, acting in good faith, adopt any and
          all actions required to cause such invalid, illegal and unenforceable provision to be validated and enforced,
          or, alternatively, to reach an agreement in relation to said null provision whereby each of the Party
          receives, as far as possible, substantially the same benefits and obligations based on valid provisions,
          provided that the Agreement is not enforced in a form that materially affects the commercial agreement between
          the Parties.
          <br />
          <strong>
            <br />
            27. GRIEVANCE REDRESSAL:
            <br />
          </strong>
          27.1 Mimik Finance offers an email-based support system. In case You require any assistance or support, You
          may access support resources or contact our support by emailing at
          <a href="mailto:support@mimik.finance" target="_blank" rel="noreferrer noopenner">
            support@mimik.finance
          </a>
          .<br />
          27.2 Mimik Finance will endeavor to revert to every query/complaint, within a reasonable time from receipt of
          the complaint.
          <br />
          27.3 The User agrees and acknowledges that the Foundation shall address and attempt to resolve the complaint
          received in accordance with the standard policies and procedures adopted by the Mimik Finance, the User’s
          disapproval/discontent with the outcome/mode of redressal shall not be deemed to mean non-redressal of the
          complaint.
          <br />
          <strong>
            <br />
            28. ORDER OF PRECEDENCE:
            <br />
          </strong>
          If there is any conflict between the DAS Description and the Service Agreement, then the Service Agreement
          will govern for all purposes, except to the extent that the DAS Description provides for any DAM Fees, in
          which case such DAS Description will prevail any conflicting terms under the Agreement.
          <br />
          <strong>
            <br />
            29. EFFECTIVE DATE:
            <br />
          </strong>
          This Service Agreement shall become effective on the day and year on which the User avails the Services, as
          contemplated under the Service Agreement.
          <br />
          <strong>
            <br />
            30. CHANGE IN TERMS:
            <br />
          </strong>
          The Foundation may update the Service Agreement without notice to you. You are encouraged to check these
          Service Agreement on a regular basis to be aware of the changes made to them. Your continued use of the
          Platform after such change shall be deemed to be your acceptance of the revised Service Agreement.
        </ServiceContent>
      </ServiceContainer>
    </>
  );
};

const ServiceContainer = styled(Box)(({ theme }) => ({
  maxWidth: '668px',
  marginBottom: '72px',
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingBottom: '0',
  paddingTop: '100px'
}));

const ServiceTitle = styled(Box)(({ theme }) => ({
  fontSize: '54px',
  textAlign: 'center',
  fontWeight: '700'
}));

const ServiceContent = styled(Box)(({ theme }) => ({
  color: '#d5d4d4',
  textAlign: 'justify',
  marginLeft: '0',
  marginRight: '0',
  paddingLeft: '0',
  fontWeight: '400',
  fontSize: '18px'
}));
