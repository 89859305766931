import { Box, Stack } from "@mui/material"
import { styled, keyframes } from "@mui/system"
import { FaArrowUp } from "react-icons/fa6"
import AOS from 'aos'
import { useEffect } from "react"
import { scrollToId } from "src/utils/utils"

const ScrollTop = (props: { isDisplay: boolean }) => {
    useEffect(() => {
        AOS.init()
        AOS.refresh()
    }, [])

    if (!props.isDisplay) {
        return null
    }

    return (
        <Container
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            onClick={() => scrollToId('#header')}
        >
            <ButtonWrapper>
                <FaArrowUp color='rgba(3, 160, 183, 1)' />
            </ButtonWrapper>
        </Container>
    )
}

const bounce = keyframes`
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(.8,0,1,1);
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,.2,1);
  }
`

const Container = styled(Box)(() => ({
    position: 'fixed',
    cursor: 'pointer',
    bottom: 20,
    right: 30,
    animation: `${bounce} 1s infinite`
}))

const ButtonWrapper = styled(Stack)(() => ({
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(6, 56, 70, 0.7)',
    borderRadius: '50%'
}))

export default ScrollTop
