import { useEffect } from 'react'
import { Box, Stack } from '@mui/material'
import { styled } from '@mui/system'
import {
  VertexLogoSvg,
  GmxLogoSvg,
  MuxLogoPng,
  ArbitrumLogoSvg,
  ChainLinkLogoSvg,
  UniswapLogoSvg
} from 'src/config/images'
import { Container } from 'src/components/Container'
import AOS from 'aos'
import 'aos/dist/aos.css'

const Protocols = () => {
  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])

  return (
    <Container>
      <ProtocolsContainer component='section'>
        <IntegratedContainer>
          <ProtocolsTitle mb={10}>
            Integrations & Partners
          </ProtocolsTitle>
          <Box sx={{ width: '90%' }}>
            <Stack spacing={4} direction='row' useFlexGap flexWrap="wrap">
              <ProtocolWrapper>
                <ProtocolsImg src={GmxLogoSvg} alt='gmx-logo' />
              </ProtocolWrapper>
              <ProtocolWrapper>
                <ProtocolsImg src={UniswapLogoSvg} alt='uniswap-logo' />
              </ProtocolWrapper>
              <ProtocolWrapper >
                <ProtocolsImg src={VertexLogoSvg} alt='vertex-logo' />
              </ProtocolWrapper>
              <ProtocolWrapper >
                <ProtocolsImg src={MuxLogoPng} alt='mux-logo' />
              </ProtocolWrapper>
              <ProtocolWrapper >
                <ProtocolsImg src={ArbitrumLogoSvg} alt='uniswap-logo' />
              </ProtocolWrapper>
              <ProtocolWrapper >
                <ProtocolsImg src={ChainLinkLogoSvg} alt='vertex-logo' />
              </ProtocolWrapper>
            </Stack>
          </Box>
        </IntegratedContainer>
      </ProtocolsContainer >
    </Container>
  )
}

export default Protocols

const ProtocolsContainer = styled(Box)(({ theme }) => ({
  height: '100svh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '40px',
  position: 'relative',
  [theme.breakpoints.down(768)]: {
    height: 'auto',
    marginTop: '140px',
    marginBottom: '10px'
  }
}))

const ProtocolsTitle = styled(Box)(({ theme }) => ({
  fontSize: '48px',
  color: '#F0F9FF',
  fontWeight: '700',
  textAlign: 'center',
  span: {
    color: '#FFC529'
  },
  [theme.breakpoints.down(768)]: {
    fontSize: '38px'
  }
}))

const IntegratedContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '20px',
  position: 'relative',
  [theme.breakpoints.down(768)]: {
    gap: '10px',
    marginBottom: '60px'
  }
}))

const ProtocolWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '24px',
  width: 'calc(100% / 3 - 70px)',
  borderRadius: '12px',
  background: 'rgb(255 255 255 / 10%)',
  [theme.breakpoints.down(540)]: {
    padding: '0',
    width: 'calc(100% / 2 - 17px)',
    background: 'unset'
  }
}))

const ProtocolsImg = styled('img')(({ theme }) => ({
  height: '48px',
  maxWidth: '100%'
}))
