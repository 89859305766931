import { styled } from '@mui/system';
import { Box, Link, Stack } from '@mui/material';
import ArrowOutward from '@mui/icons-material/ArrowOutward';
import { OrnHeroBg, OrnHero2Bg } from 'src/config/images';

const HeroBanner = () => {
  return (
    <HeroBannerContainer flexDirection="row" alignItems="center" justifyContent="end">
      <BgImg src={OrnHeroBg} alt="bg-img" />
      <Box width={{ xs: '93%', md: '41%' }}>
        <HeroBannerTitle mb={4}>
          Asset Management
          <div className="highlighted">Across EVM, BRC-20</div>
          and Other Chains
        </HeroBannerTitle>
        <HeroBannerContent mb={5}>
          Access Mimik’s Omni-Chain Solutions with Direct Fiat Deposits and through EVM, BTC (BRC-20), and Non-EVM
          Chains
        </HeroBannerContent>
      </Box>
      <Box
        sx={{
          position: 'relative',
          display: {
            xs: 'none',
            md: 'inherit'
          }
        }}
        width="50%"
      >
        <Image src={OrnHero2Bg} />
      </Box>
    </HeroBannerContainer>
  );
};

export default HeroBanner;

const HeroBannerContainer = styled(Stack)(() => ({
  color: '#EBFFFF',
  height: 'calc(100svh - 170px)',
  position: 'relative'
}));

const HeroBannerTitle = styled(Box)(({ theme }) => ({
  fontSize: '55px',
  fontWeight: '800',
  width: '100%',
  lineHeight: '67px',
  [theme.breakpoints.down(768)]: {
    fontSize: '34px',
    lineHeight: '50px'
  },
  '& .highlighted': {
    color: '#A2FBFF'
  }
}));

const HeroBannerContent = styled(Box)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: '300',
  width: '100%',
  [theme.breakpoints.down(768)]: {
    padding: '0',
    width: '84%'
  }
}));

const CtaButton = styled(Link)(() => ({
  display: 'inline-flex',
  justifyContent: 'center',
  padding: '16px 32px',
  fontSize: '18px',
  fontWeight: 400,
  borderRadius: '12px',
  border: '2px solid #A2FBFF',
  textDecoration: 'none',
  transition: '.3s ease-in-out 0s',
  '&:hover': {
    backgroundColor: 'rgb(28, 230, 244)',
    transform: 'scale(1.14)'
  },
  '&:active': {
    backgroundColor: 'rgb(28, 230, 244)'
  },
  '& svg': {
    marginLeft: '10px'
  }
}));

const Image = styled('img')(() => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  right: 0
}));

const BgImg = styled('img')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '120%',
  top: '0',
  left: '0',
  zIndex: -1,
  [theme.breakpoints.down(540)]: {
    width: '100%',
    height: '100%',
    top: '0',
    right: 0
  }
}));
