import { useEffect } from 'react';
import { TextSnippet } from '@mui/icons-material';
import {Box, Grid, Table, TableBody, TableCell, TableContainer, TableRow} from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';

export const Contracts = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  const traderWallets: string[] = [
    '0x5acF02b371e3DF1cbC88e321BaE8D6d3ab819265',
    '0xcDA5446a72f590899eE987a2855524d27c1F25C8',
    '0x2aAd23731E183645275fbc6B113990c3440BC7B7'
  ]

  const userVaults: string[] = [
    '0xcCb35FD614E36323aa83eF45b5544822eF48130e',
    '0xc5E45b728E0Cc877236239b7a8d49894E6Ad1945',
    '0xaCBFD72BFD67419560654B50d8175b2Cde725466'
  ]

  return (
    <ContractContainer mb={24}>
      <ContractTitle>Contracts</ContractTitle>
      <Grid container spacing={8}>
        <ContractItem title={'Trader Wallet'} content={traderWallets} />
        <ContractItem title={'User Vault'} content={userVaults} />
      </Grid>
    </ContractContainer>
  );
};

const ContractContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '72px',
  paddingTop: '70px'
}));

const ContractTitle = styled(Box)(({ theme }) => ({
  fontSize: '54px',
  color: '#F5B405',
  fontWeight: '600'
}));

interface DocsItemProps {
  title: string;
  content: string[];
}

const ContractItem = (props: DocsItemProps) => {
  const { title, content } = props;
  return (
    <Grid item xs={12} md={6}>
      <DocsItemWrapper>
        <DocsItemTitle>
          <TextSnippet fontSize="large" />
          {title}
        </DocsItemTitle>
        <DocsItemContent>
          <TableContainer>
            <Table>
              <TableBody>
                {content.map((address, index) => (
                  <TableRow key={address}>
                    <TableCell>
                      {index + 1}
                    </TableCell>
                    <TableCell>
                      {address}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DocsItemContent>
      </DocsItemWrapper>
    </Grid>
  );
};

const DocsItemWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px'
}));

const DocsItemTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  fontSize: '24px',
  fontWeight: '600',
  color: '#FFF',
  '&:hover': {
    color: '#FFC529'
  }
}));

const DocsItemContent = styled(Box)(({ theme }) => ({
  fontSize: '18px',
  color: '#9c9c9c',

  '.MuiTableCell-body': {
    fontSize: '18px',
    border: '1px solid #2054A5'
  }
}));
