import { useEffect } from 'react';
import { Box, Stack, Link } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { Container } from 'src/components/Container';
import {
  KunjiLogoPng
} from 'src/config/images';
import AOS from 'aos';
import 'aos/dist/aos.css';

interface IFooterItem {
  label: string,
  url?: string,
  target?: string,
}
interface IFooterData {
  title: string,
  items: IFooterItem[]
}
const footerData: IFooterData[] = [
  {
    title: 'Get Started',
    items: [{
      label: 'What is Mimik Finance',
      url: 'https://mimik.gitbook.io/mimik-finance'
    }, {
      label: 'About Us',
      url: '/about'
    }, {
      label: 'Roadmap',
      url: 'https://mimik.gitbook.io/mimik-finance/roadmap'
    }, {
      label: 'Products',
      url: '/#products',
      target: '_self'
    }, {
      label: 'Media Assets',
      url: 'https://mimik.gitbook.io/mimik-finance/media-assets'
    }]
  }, {
    title: 'Documentation',
    items: [{
      label: 'Gitbook',
      url: 'https://mimik.gitbook.io/mimik-finance'
    }, {
      label: 'Tokenomics',
      url: 'https://mimik.gitbook.io/mimik-finance/tokenomics'
    }, {
      label: 'Contracts',
      url: '/contracts'
    }, {
      label: 'Blogs',
      url: 'https://medium.com/@mimikfinance'
    }, {
      label: 'FAQs',
      url: '/faqs'
    }]
  }, {
    title: 'Community',
    items: [{
      label: 'Twitter',
      url: 'https://twitter.com/mimikfinance'
    }, {
      label: 'Discord',
      url: 'https://discord.gg/jvXqHYyA'
    }, {
      label: 'Telegram',
      url: 'https://t.me/mimikfinance'
    }, {
      label: 'LinkedIn',
      url: 'https://www.linkedin.com/company/mimikfinance/'
    }, {
      label: 'YouTube',
      url: 'https://www.youtube.com/@mimikfinance'
    }]
  }
]

const FooterColumn = ({ data }: { data: IFooterData }) => {
  const { title, items } = data
  return (
    <Stack direction='column' justifyContent='start' alignItems={{ xs: 'center', md: 'end' }}>
      <FooterColumnTitle>{title}</FooterColumnTitle>
      {items.map(item => (
        <FooterColumnItem
          key={item.label}
          href={item.url}
          target={item.target ?? '_blank'}
        >{item.label}</FooterColumnItem>
      ))}
    </Stack>
  )
}

export const Footer = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  const navigate = useNavigate();
  return (
    <FooterContainer>
      <Container>
        <FooterSection>
          <FooterItemContainer>
            <FooterLogoItem href='/'>
              <KunjiLogo src={KunjiLogoPng} alt="kunji-logo" />
            </FooterLogoItem>
            <QuickMenuContainer>
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 15 }}>
                {footerData.map(data => (
                  <FooterColumn key={data.title} data={data} />
                ))}
              </Stack>
            </QuickMenuContainer>
          </FooterItemContainer>
          <FooterDivider />
          <FooterTextItemContainer>
            <FooterText>© 2023 Kunji DAO Foundation. All rights reserved.</FooterText>
            <FooterLinkContainer>
              <FooterText
                sx={{ textAlign: 'center', cursor: 'pointer' }}
                onClick={() => navigate('/terms-and-conditions')}
              >
                Terms
              </FooterText>
              <FooterText sx={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => navigate('/privacy-policy')}>
                Privacy
              </FooterText>
              <FooterText
                sx={{ textAlign: 'center', cursor: 'pointer' }}
                onClick={() => navigate('/service-agreement')}
              >
                Service Agreement
              </FooterText>
            </FooterLinkContainer>
          </FooterTextItemContainer>
        </FooterSection>
      </Container>
    </FooterContainer>
  );
};

const FooterContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: '#0E0E0E',
  padding: '25px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  [theme.breakpoints.down(420)]: {
    padding: '45px 0'
  }
}));

const FooterSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  [theme.breakpoints.down(1140)]: {
    alignItems: 'center'
  }
}));

const FooterItemContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '40px',
  justifyContent: 'space-between',
  alignItems: 'start',
  paddingTop: '15px',
  paddingBottom: '15px',
  [theme.breakpoints.down(1140)]: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: '80px'
  },
  [theme.breakpoints.down(840)]: {
    gap: '40px'
  }
}));

const FooterDivider = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '0.25px',
  backgroundColor: '#FFF',
  marginBottom: '12px'
}));

const FooterTextItemContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '40px',
  justifyContent: 'space-between',
  paddingRight: 50,
  [theme.breakpoints.down(1140)]: {
    flexDirection: 'column-reverse',
    width: '600px'
  },
  [theme.breakpoints.down(840)]: {
    width: '450px'
  },
  [theme.breakpoints.down(540)]: {
    width: '300px',
    paddingRight: 0
  }
}));

const FooterLogoItem = styled(Link)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  width: '305px',
  [theme.breakpoints.down(1140)]: {
    alignItems: 'center',
    width: '600px'
  },
  [theme.breakpoints.down(840)]: {
    width: '450px'
  },
  [theme.breakpoints.down(540)]: {
    width: '300px'
  }
}));

const KunjiLogo = styled('img')(({ theme }) => ({
  width: '125px',
  height: 'auto',
  cursor: 'pointer'
}));

const FooterText = styled(Box)(({ theme }) => ({
  fontSize: '14px',
  color: '#ffffffc9'
}));

const QuickMenuContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '30px'
}));

const FooterLinkContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '38px',
  [theme.breakpoints.down(1140)]: {
    justifyContent: 'space-between'
  }
}));

const FooterColumnTitle = styled(Box)(() => ({
  fontSize: 14,
  fontWeight: 600,
  marginBottom: 16,
  color: '#CEFEFF'
}))

const FooterColumnItem = styled(Link)(() => ({
  textDecoration: 'none',
  fontSize: 14,
  fontWeight: 400,
  margin: 4,
  color: '#B1C2C2',
  textAlign: 'right'
}))
