import { AiOutlineMinusCircle } from "react-icons/ai";
import { IoMdAddCircleOutline } from "react-icons/io";
import { Box, Stack, Collapse, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/system';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container } from 'src/components/Container';
import { Deposit1Png, Deposit2Png, Deposit3Png, Deposit4Png, Withdraw1Png } from 'src/config/images';

const Tab = styled('a')(() => ({
  padding: '12px 68px',
  borderRadius: 6,
  fontSize: 16,
  fontWeight: 600,
  color: '#03A0B7',

  '&.active': {
    color: '#063846',
    backgroundColor: '#1CE6F4'
  }
}))

const Tabs = () => {
  const { hash } = useLocation()
  console.log('hash', hash)

  return (
    <Box sx={{ margin: '0 auto 60px' }}>
      <Stack
        flexDirection='row'
        sx={{
          background: '#063846',
          border: '1px solid #145465',
          boxShadow: '0 3px 15px 0 #00C8D9BF'
        }}
      >
        <Tab href="#general" className={"general " + (hash === '#general' ? 'active' : '')} style={{ textDecoration: 'none' }}>
          General
        </Tab>
        <Tab href="#platform" className={"platform " + (hash === '#platform' ? 'active' : '')} style={{ textDecoration: 'none' }}>
          Platform
        </Tab>
      </Stack>
    </Box>
  )
}
export const FAQs = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);
  const faqsData = {
    general: [
      {
        id: 1,
        title: 'What is Mimik Finance?',
        content:
          "Mimik Finance is a decentralized social trading platform. Formerly known as Mimik Finance, it empowers users to effortlessly copy entire portfolios, enabling everyone to benefit from the expertise of seasoned traders. Additionally, the platform provides traders with the tools they need to create and manage multi-asset vaults, further expanding the opportunities for wealth creation and management in the crypto landscape."
      }
      // {
      //   id: 2,
      //   title: 'What is the difference between Mimik Finance and Crypto Hedge Funds?',
      //   content: <DiffHedge />
      // },
      // {
      //   id: 3,
      //   title: 'What are Mimik Finance’s strategies?',
      //   content: (
      //     <>
      //       <FaqTextListContainer>
      //         <FaqText>
      //           This on-chain asset management platform offers three actively managed strategies where users can invest
      //           per their risk appetite. All the strategies are regulated and curated with robust risk management
      //           tactics and an optimal frontier framework. They are :
      //         </FaqText>
      //         <ol>
      //           <li>
      //             <FaqText>Alpha Blue Chip Focused Strategy</FaqText>
      //           </li>
      //           <li>
      //             <FaqText>Top Cap Digital Assets Strategy</FaqText>
      //           </li>
      //           <li>
      //             <FaqText>Arbitrage Opportunities and Balanced Strategy</FaqText>
      //           </li>
      //         </ol>
      //         <FaqText>Learn more about these strategies on the About us page of the website.</FaqText>
      //       </FaqTextListContainer>
      //     </>
      //   )
      // },
      // {
      //   id: 4,
      //   title: 'How can I get started?',
      //   content: (
      //     <>
      //       <FaqTextListContainer>
      //         <FaqText>You can start your investment with a few steps:</FaqText>
      //         <ol>
      //           <li>
      //             <FaqText>Connect your Metamask or any other DeFi wallet on the platform.</FaqText>
      //           </li>
      //           <li>
      //             <FaqText>Check out the strategies and find the ideal for your risk appetite.</FaqText>
      //           </li>
      //           <li>
      //             <FaqText>Invest in the strategy and track the real-time portfolio value.</FaqText>
      //           </li>
      //         </ol>
      //         <FaqText>
      //           We will be launching our Dapp soon. For more information and updates, join our community. Avail multiple
      //           early bird benefits by joining our waitlist here: Mimik Finance.
      //         </FaqText>
      //       </FaqTextListContainer>
      //     </>
      //   )
      // },
      // {
      //   id: 5,
      //   title: 'Is there any lock-in period for the funds?',
      //   content:
      //     'Unlike hedge funds and other investment funds, we do not have any lock-in policy. Investors are free to exit the strategy at any point in time. It will take up to 20 hours for the fund to reflect in the user’s wallet.'
      // },
      // {
      //   id: 6,
      //   title: 'Does Mimik Finance offer a Fiat gateway to get started with the investment?',
      //   content:
      //     'No, investment through Fiat gateway is not available on Mimik Finance. The user needs to connect their decentralized wallet to get started with the investment.'
      // },
      // {
      //   id: 7,
      //   title: 'Does Mimik Finance offer a Fiat gateway to get started with the investment?',
      //   content:
      //     'No, Mimik Finance is not a liquidity provider. It is a decentralized digital asset management platform that actively manages the funds of clients based on an investment thesis. While liquidity providers just provide liquidity. They don’t manage funds.'
      // },
      // {
      //   id: 8,
      //   title: 'Why don’t we have an automated asset management protocol?',
      //   content:
      //     "Automated asset management protocols are used for yield-based strategies. Mimik Finance utilizes discretionary long/ short strategies based on the quadrangulation of factors like market sentiments and technical and fundamental analysis, and on-chain data, which can't be automated. To maintain the proper trading process to generate greater returns with mitigated risk, Mimik Finance has an in-house team of digital asset managers who will trade the fund as per the regulation and governance guidelines and have no right to withdraw them or manipulate their strategies. "
      // }
    ],
    platform1: [
      {
        id: 9,
        title: 'Can digital asset managers manipulate the trades?',
        content:
          'No. Digital asset managers or even the core team cannot manipulate the trades. No asset manager can take trade-in non-whitelisted tokens. All the whitelisted tokens under Mimik Finance’s strategies are highly liquid. Further improvements and additions will be made as per the governance guidelines. Moreover, the share of asset allocation is also limited for all the asset classes. '
      },
      {
        id: 10,
        title: 'Which trading platforms be used by Mimik Finance?',
        content:
          'Mimik Finance will be using decentralized exchanges for trade. The platform will start with trades on GMX and Uniswap.'
      }
    ],
    platform: [
      {
        id: 11,
        title: 'How to Deposit?',
        content: <DepositExplain />
      },
      {
        id: 12,
        title: 'How to Withdraw?',
        content: <WithdrawExplain />
      },
      {
        id: 13,
        title: 'Will I be able to track all the trades?',
        content:
          'Yes, you can track your real-time portfolio value on the platform. You can also see all the trades and other important metrics on the dashboard, enabling 100% transparency.'
      },
      {
        id: 14,
        title: 'Why is the protocol built on the Arbitrum chain?',
        content:
          'Arbitrum is a scaling solution that sits above the base layer of the Ethereum blockchain, offering cheap and fast transactions. It is designed to enhance the Ethereum smart contracts in terms of speed while adding additional privacy features. So, we have our protocol on the Arbitrum chain for fast transactions and low gas fees for users.'
      }
    ]
  };

  useEffect(() => {
    const sections = document.querySelectorAll('section');
    window.onscroll = () => {
      let current: any;
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        if (window.pageYOffset >= sectionTop - 60) {
          current = section.getAttribute('id');
        }
      });
    };
  }, []);

  return (
    <Container>
      <FaqsContainer mb={20}>
        <FaqsHeader>
          <FaqsSubTitle>Frequently Ask Questions</FaqsSubTitle>
          <Tabs />
        </FaqsHeader>
        <FaqItemContainer>
          <FaqItemSection id="general">
            {faqsData.general.map((faq) => (
              <FaqItem title={faq.title} content={faq.content} key={faq.id} />
            ))}
          </FaqItemSection>
        </FaqItemContainer>
      </FaqsContainer>
    </Container>
  );
};

const FaqsContainer = styled(Box)(({ theme }) => ({
  paddingTop: '100px',
  display: 'flex',
  flexDirection: 'column',
  color: '#EBFFFF',
  gap: '60px'
}));

const FaqsHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column'
}));

const FaqsSubTitle = styled(Box)(({ theme }) => ({
  fontSize: '48px',
  textAlign: 'center',
  fontWeight: '700',
  marginBottom: '48px',
  [theme.breakpoints.down(540)]: {
    fontSize: '36px'
  }
}));

const FaqItemContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '1060px',
  margin: '0 auto',
  [theme.breakpoints.down(768)]: {
    width: '100%'
  }
}));

const FaqItemSection = styled('section')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '26px'
}));

function createData(kunji: string, hedge: string) {
  return { kunji, hedge };
}

const rows = [
  createData('Decentralized', 'Decentralized'),
  createData('No minimum initial investment', 'Minimum initial investment starting $100K'),
  createData('No entry barrier', 'Only for accredited investors'),
  createData('Transparency with real-time tracking and periodical reports', 'Lacks transparency'),
  createData('No management or platform fee', 'Includes multiple fees'),
  createData('No custody of funds', 'Have custody of funds'),
  createData('No lock-in', 'Lock-in period'),
  createData('Governance', 'NIL')
];

const DiffHedge = () => {
  return (
    <DiffHedgeContainer>
      <FaqText>
        Mimik Finance is a non-custodial digital asset management platform. It offers multiple investment strategies
        based on the investors' risk appetite. It allows the investors to connect their wallets and invest with no entry
        barrier and minimum initial investment. Digital Asset Managers (DAMs) trade the same amount on decentralized
        exchanges, and the real-time portfolio value is updated for the users to track.
      </FaqText>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <CustomTableCell align="center" sx={{ backgroundColor: '#2054A5' }}>
                Mimik Finance
              </CustomTableCell>
              <CustomTableCell align="center" sx={{ backgroundColor: '#2054A5' }}>
                Hedge Funds
              </CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <CustomTableCell align="center">{row.kunji}</CustomTableCell>
                <CustomTableCell align="center">{row.hedge}</CustomTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </DiffHedgeContainer>
  );
};

const DiffHedgeContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '60px',
  flexDirection: 'column'
}));

const FaqText = styled(Box)(({ theme }) => ({
  fontSize: '18px',
  [theme.breakpoints.down(540)]: {
    fontSize: '14px'
  }
}));

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid #FFF'
}));

const FaqTextListContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px'
}));

const DepositExplain = () => {
  return (
    <StepExplainContainer>
      <StepItem>
        <StepItemText>Step1: Visit beta.mimik.finance and connect your wallet (as shown in the image)</StepItemText>
        <img src={Deposit1Png} alt="deposit-img" />
      </StepItem>
      <StepItem>
        <StepItemText>Step 2: Go to 'Strategy' section and click on 'Invest' button</StepItemText>
        <img src={Deposit2Png} alt="deposit-img" />
      </StepItem>
      <StepItem>
        <StepItemText>
          Step 3: Enter the maximum spending cap for the contract, and in the next step, approve the transactions from
          your wallet.
        </StepItemText>
        <img src={Deposit3Png} alt="deposit-img" />
      </StepItem>
      <StepItem>
        <StepItemSubText>
          Note: Rollover occurs every 3 hours. After the rollover, you can view your funds in the Dashboard section and
          claim them to add to your wallet (shown in the below image)
        </StepItemSubText>
        <img src={Deposit4Png} alt="deposit-img" />
      </StepItem>
    </StepExplainContainer>
  );
};

const WithdrawExplain = () => {
  return (
    <StepExplainContainer>
      <StepItem>
        <StepItemText>
          Step1: Visit the "strategy" section and click on "three dots" to withdraw funds(as shown in the image)
        </StepItemText>
        <img src={Withdraw1Png} alt="withdraw-img" />
      </StepItem>
      <StepItem>
        <StepItemText>
          Step 2: Go to 'Dashboard' and click on 'Claim'
          <StepItemSubText>
            Note: You can only claim after 3 hours from the time you submit a withdrawal request, as rollover occurs
            every 3 hours.
          </StepItemSubText>
        </StepItemText>
        <img src={Withdraw1Png} alt="withdraw-img" />
      </StepItem>
    </StepExplainContainer>
  );
};

const StepExplainContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '15px',
  flexDirection: 'column',
  width: '100%'
}));

const StepItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px'
}));

const StepItemText = styled(Box)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: '700',
  [theme.breakpoints.down(540)]: {
    fontSize: '14px'
  }
}));

const StepItemSubText = styled(Box)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: '300',
  fontStyle: 'italic'
}));

interface FaqItemProps {
  title: string;
  content: string | React.ReactNode;
}

const FaqItem = (props: FaqItemProps) => {
  const { title, content } = props;
  const [isOpen, setOpen] = useState(false);
  return (
    <FaqItemWrapper>
      <FaqItemHeader onClick={() => setOpen(!isOpen)}>
        {title}
        {isOpen ? <AiOutlineMinusCircle /> : <IoMdAddCircleOutline />}
      </FaqItemHeader>
      <Collapse in={isOpen}>
        <FaqItemText>{content}</FaqItemText>
      </Collapse>
    </FaqItemWrapper>
  );
};

const FaqItemWrapper = styled(Box)(({ theme }) => ({
  padding: '0 25px',
  background: '#06384626',
  borderRadius: '25px'
}));

const FaqItemHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '20px 0',
  cursor: 'pointer',
  fontSize: '16px',
  fontWeight: '600',
  gap: '20px',
  [theme.breakpoints.down(540)]: {
    fontSize: '16px'
  }
}));

const FaqItemText = styled(Box)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 400,
  paddingBottom: '20px',
  [theme.breakpoints.down(540)]: {
    fontSize: '14px'
  }
}));
