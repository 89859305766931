import { useEffect } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';

export const Terms = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);
  return (
    <TermsContainer mb={24}>
      <TermsTitle className="txt-center">Terms &amp; Conditions</TermsTitle>
      <TermsContent>
        <strong>
          <br />
          Mimik Finance Terms &amp; Conditions
          <br />
          Last Updated on: August 4th, 2023
          <br />
          <br />
          Kunji DAO Foundation, (the “Foundation”; “We”; “Us”; “Our”), is the owner of the website domain hosted at
          www.mimik.finance &nbsp;(“Website”). Mimik Finance a Decentralized Finance protocol that allows investors in
          Virtual Digital Asset classes to invest in Digital Asset Strategies (“DAPs”) that are actively managed by
          Digital Asset Managers (“DAMs”) based on their risk appetite, time horizon and other such preferences offered
          by through the Website or other application(s) (collectively known as “Mimik Finance” or “Platform”). Mimik
          Finance is a technology service acting as an intermediary by aggregating various services such as Trading
          Facilities/Exchanges, Wallets, DAMs onto Mimik Finance (“Services”).PLEASE READ THE FOLLOWING TERMS AND
          CONDITIONS VERY CAREFULLY AS YOUR USE OF THE PLATFORM IS SUBJECT TO YOUR ACCEPTANCE OF AND COMPLIANCE WITH THE
          FOLLOWING TERMS AND CONDITIONS ("TERMS" / “AGREEMENT”).BY ACCESSING OR OTHERWISE USING THE PLATFORM, YOU
          ACCEPT AND AGREE TO ALL OF THE TERMS AND CONDITIONS CONTAINED HEREIN.WHILE USING THE PLATFORM, YOU MAY BE
          SUBJECT TO ANY POSTED GUIDELINES OR RULES APPLICABLE TO THE PLATFORM. BY USING OR ACCESSING THE PLATFORM, YOU
          DE-FACTO AGREE TO THE FOLLOWING TERMS.THESE TERMS CONSTITUTE A LEGALLY BINDING AGREEMENT BETWEEN YOU AND THE
          FOUNDATION WHILE USING THE PLATFORM AND SHALL GOVERN THE FOUNDATION’S RELATIONSHIP WITH YOU IN RELATION TO THE
          USAGE OF THE PLATFORM. BY USING OR ACCESSING PLATFORM OR AVAILING THE SERVICES, YOU DE-FACTO AGREE TO THE
          FOLLOWING TERMS EITHER IN YOUR INDIVIDUAL CAPACITY OR AS AN AUTHORISED REPRESENTATIVE OF A THIRD PARTY. IN
          FURTHERANCE OF THE FOREGOING, YOU ALSO REPRESENT THAT YOU HAVE READ AND UNDERSTOOD ALL OF THE PROVISIONS OF
          THIS AGREEMENT. YOUR USE OR ACCESS TO THE PLATFORM AND ANY REGISTRATION ON MIMIK FINANCE SHALL BE DEEMED TO BE
          YOUR CONSENT TO THESE TERMS.YOU ACKNOWLEDGE THAT INVESTING IN CRYPTO ASSETS, CRYPTOCURRENCIES AND OTHER
          VIRTUAL DIGITAL ASSETS (COLLECTIVELY “VIRTUAL DIGITAL ASSETS”) INVOLVES A HIGH DEGREE OF RISK. VIRTUAL DIGITAL
          ASSETS ARE SUBJECT TO CONSTANT AND FREQUENT FLUCTUATIONS IN VALUE, AND THE VALUE OF YOUR VIRTUAL DIGITAL
          ASSETS MAY INCREASE OR DECREASE AT ANY TIME. ACCORDINGLY, YOU MAY SUFFER A COMPLETE LOSS OF THE FUNDS. YOU
          ACKNOWLEDGE THAT YOU ARE SOLELY RESPONSIBLE FOR DETERMINING THE NATURE, SUITABILITY, AND APPROPRIATENESS OF
          THESE INVESTMENT RISKS FOR YOU. YOU ACKNOWLEDGE AND AGREE THAT THE FOUNDATION BEARS NO RESPONSIBILITY OR
          LIABILITY TO YOU OR ANY OTHER PERSON WHATSOEVER FOR ANY LOSSES OR GAINS INCURRED IN CONNECTION WITH YOUR USE
          OF THE PLATFORM AND SERVICES. YOU ALSO ACKNOWLEDGE AND AGREE THAT THE FOUNDATION DOES NOT GIVE ADVICE OR
          RECOMMENDATIONS REGARDING INVESTING IN VIRTUAL DIGITAL ASSETS, INCLUDING THE SUITABILITY AND APPROPRIATENESS
          OF, AND INVESTMENT STRATEGIES FOR, VIRTUAL DIGITAL ASSETS.IF YOU DO NOT AGREE TO ALL OR ANY OF THESE TERMS, DO
          NOT USE THE PLATFORM.IF YOU CONTINUE TO USE THIS PLATFORM, YOU ARE AGREEING TO BE BOUND BY THESE TERMS, WHICH
          ALONG WITH THE PRIVACY POLICY AVAILABLE AT https://www.Mimik.finance/privacy-policy &nbsp;AND ANY OTHER
          VARIATIONS THEREOF, (THE “PRIVACY POLICY”) GOVERN YOUR RELATIONSHIP WITH MIMIK FINANCE.
          <br />
          <br />
          1. DEFINITIONS:
          <br />
          <br />
        </strong>
        1.1. “User(s)”; “your”; “you” shall mean an individual user who has access to use the Platform in order to avail
        the Platform and Services.
        <br />
        1.2. “User Account” or “Investor Account” shall mean the account on purchase of the Service(s), created for you
        to enable you to avail the Services through the Platform.
        <strong>
          <br />
          <br />
          2. SCOPE OF THE PLATFORM:
          <br />
          <br />
        </strong>
        2.1. The Foundation facilitates Services that are offered through the Platform.
        <br />
        2.2. You will be able to view and select the Services that you wish to avail through the Platform and pay for
        the same through the payment modes made available on the Platform. Upon creation of User Account and accepting
        the Service Agreement (provided at the time of account creation) you can avail the Services provided by The
        Foundation.
        <br />
        2.3. You understand and acknowledge that your use of the Platform is at your own risk and The Foundation shall
        not be liable for any consequences arising from such use.
        <strong>
          <br />
          <br />
          3. ACCESS TO THE PLATFORM (ELIGIBILITY):
          <br />
          <br />
        </strong>
        3.1. By accessing or using the Platform, you represent and warrant that you are of the age of majority in the
        jurisdiction in which you reside. The Foundation reserves the right to terminate your access to the Platform
        and/or refuse to provide you with access to the Platform if it discovers that you are a minor.
        <br />
        3.2. You represent and warrant that you are not are not a US citizen, or currently or ordinarily located or
        resident in (or incorporated or organized in) the United States of America or any other restricted jurisdiction.
        <br />
        3.3. You are not, and do not directly or indirectly own or control, and have not received any assets from any
        blockchain address that is listed on any sanctions list or equivalent maintained by any international bodies or
        lists recognised by the United Nations or any of its member states.{' '}
        <strong>
          <br />
        </strong>
        3.4. You represent and warrant that your access to the Platform does not violate the laws applicable to you. You
        confirm that your use or access to the Platform would not constitute activity which would violate, or assist in
        violation of, any law, statute, ordinance, or regulation, sanctions programs administered in any relevant
        country, including but not limited to the U.S. Department of Treasury's Office of Foreign Assets Control
        ("OFAC").
        <br />
        3.5. Accessing, browsing, subscribing or any other action You undertake with respect to the Platform does not
        deem to make you an agent, member, partner or affiliate of the Foundation for any purposes whatsoever.
        <br />
        3.6. With respect to your User Account, you agree to:a) provide accurate, authenticated and true information
        about yourself;b) maintain the security of your passwords and identification;c) promptly update the email
        address listed in connection with your User Account to keep it accurate so that we can contact you; and
        <strong>
          <br />
        </strong>
        3.7. Unauthorized Use; False Information: You shall: (i) notify us immediately of any unauthorized use of your
        User Account or any other known or suspected breach of security, (ii) use reasonable efforts to stop any
        unauthorized use of the Platform or your User Account that is known to you or suspected, and (iii) not provide
        false identity or information to gain access to or use the Platform.
        <strong>
          <br />
          <br />
          4. USE OF SERVICE(S)
          <br />
          <br />
        </strong>
        4.1. Subject to these Terms, you are hereby granted a fixed-term, non-exclusive, limited, non- transferable,
        revocable, non-sub licensable, license to use and access the Platform and Services for your personal,
        non-commercial use only and as permitted by the features of the Services in accordance with these Terms. The
        Foundation grants you this license as set forth in these Terms, provided that: (i) You will not copy,
        distribute, or disclose any part of the Services in any medium; (ii) You will not alter or modify any part of
        the Services without prior written consent of The Foundation; (iii) You agree not to sell, license, rent
        reproduce, transmit, publicly display, publicly perform, publish, adapt, reverse engineer, edit or create
        derivative works from any Services and (iv) You will otherwise comply with these Terms.
        <br />
        4.2. Nothing herein contained shall be construed as granted to you any intellectual property right, which
        includes copyrights, regarding the Platform and/or Services except as expressly provided for hereunder. The
        Foundation reserves all rights not expressly granted herein. Use of the Platform for any purpose not expressly
        permitted by these Terms is strictly prohibited.
        <br />
        4.3. The Foundation does not guarantee that the Platform will be secure or free from bugs or viruses. You are
        responsible for configuring your information technology, computer programs and platform to access our Platform.
        You should use your own appropriate virus protection software. You must not misuse our Platform by introducing
        viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful. You must
        not attempt to gain unauthorized access to our Platform, the server on which our Platform is stored, or any
        server, computer or database connected to our Platform. You must not attack our Platform via a denial-of-service
        attack or a distributed denial-of service attack. The Foundation will report any breach of this term in our
        discretion to the relevant law enforcement authorities and we will co-operate with those authorities by
        disclosing your identity to them. In the event of such a breach, your right to use our Platform will cease
        immediately.
        <strong>
          <br />
          <br />
          5. USER ACCOUNT SECURITY:
          <br />
          <br />
        </strong>
        5.1. You will be responsible for maintaining the confidentiality of User Account and are fully responsible for
        all activities that occur under User Account. You agree to immediately notify The Foundation of any unauthorized
        use of your User Account or any other breach of security. The Foundation cannot and will not be liable for any
        loss or damage arising from your failure to comply with this provision.
        <br />
        5.2. You agree to use the Platform only: (i) for purposes that are permitted by these Terms; and (ii) in strict
        compliance with any applicable law, regulation or generally accepted practices or guidelines. You agree not to
        engage in activities that may adversely affect the use of the Platform by The Foundation or other Users.5.3. You
        agree not to access (or attempt to access) the Platform by any means other than through the interface that is
        provided by the Foundation. You shall not use any deep-link, robot, spider or other automatic device, program,
        algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any
        portion of the Platform, or in any way reproduce or circumvent the navigational structure or presentation of the
        Platform, materials or any &nbsp;Foundation property, to obtain or attempt to obtain any materials, documents or
        information through any means not specifically made available through the Platform.
        <strong>
          <br />
          <br />
          6. PAYMENT TERMS:
          <br />
          <br />
        </strong>
        6.1. The Platform utilizes blockchain based payment mechanism including but limited to stablecoins. You further
        represent and warrant that you will not use any virtual currency or Virtual Digital Asset or instrument that is
        not lawfully owned by you. The Platform disclaims all liabilities arising out of loss of any information
        pertaining to the confidentiality of any &nbsp;Virtual Digital Asset related credentials such as wallet related
        cryptographic keys. In addition to these Terms, the terms and conditions of the concerned third party service
        provider or other financial institution or applicable Virtual Digital Asset service providers shall also be
        applicable to you. The Platform disclaims any liability arising out of the failure to process payments by such
        third parties.
        <br />
        6.2. If the Foundation terminates your account; or if you close your account, or if the payment cannot be
        completed for any reason, you shall be obligated to pay the Foundation for all unpaid fees plus any penalties,
        as may be applicable.6.3. The Platform is merely a facilitator for providing you with payment channels through
        automated online electronic payments, collection and remittance facility for the payment of Services availed by
        you on the Platform using the existing blockchain infrastructure or virtual asset service providers.
        <br />
        <strong>
          <br />
          7. CONTENT ON PLATFORM:
          <br />
          <br />
        </strong>
        7.1. The Platform content is provided for general information only.
        <br />
        7.2. Although the Foundation takes reasonable efforts to update the Platform content, The Foundation makes no
        representations, warranties or guarantees, whether express or implied, that the content on our Platform is
        accurate, complete or up to date.
        <br />
        7.3. The Foundation shall not be held liable under any circumstances including, but not limited to: any
        infringement, errors, damages, fraud, misrepresentations, direct or indirect losses, future business loss,
        liability, claims or omission of information or details posted, or any link accessible or made available through
        this Platform. The Foundation reserves the right to modify the Platform content at any time. You agree that such
        modified content shall be binding upon you, and you agree to abide by the same. You agree that you are solely
        responsible for your use of the Services made available through the Platform, and the Foundation shall not be
        responsible and/or liable for any consequences arising from such use.
        <br />
        7.4. The content does not constitute financial or investment advice. Any financial or investment related
        questions should be promptly directed towards your financial or investment adviser. You should consult with a
        financial or investment adviser prior to your use of the Platform and the Services. The content, including but
        not limited to all financial market analysis and predictions, text, photographs, images, graphics, audio, video,
        and other materials, along with any and all content and third party content, is not intended to be, and should
        not be used in place of: The advice of a financial or investment adviser; or A consultation, call, or visit with
        a financial or investment adviser. The content should not be used for long-term financial planning. You should
        not disregard, or delay in seeking, financial advice from a financial or investment adviser as a result of any
        of the content. YOUR TRANSMISSION AND RECEIPT OF THE CONTENT, IN WHOLE OR IN PART, OR YOUR COMMUNICATION WITH
        THE FOUNDATION AND THE SERVICES THROUGH THE INTERNET, EMAIL, OR ANY OTHER MEANS DOES NOT CONSTITUTE OR CREATE A
        FIDUCIARY RELATIONSHIP BETWEEN THE FOUNDATION AND YOU.
        <strong>
          <br />
          <br />
          8. THIRD PARTY LINKS
          <br />
          <br />
        </strong>
        The Platform may contain links or advertisements of third parties. Any transaction, dealings or communication
        otherwise that the User may have with such third parties are at the User’s own risk and we make no warranties,
        express or implied regarding the quality or suitability of the Services of such third parties, or payment
        gateways. User may be redirected to a third-party website upon clicking on such links, these third-party
        websites will be governed by the third-party’s privacy policy and terms of use. We urge that the User read,
        understand and accept the third party’s terms of use and privacy policy.
        <br />
        <strong>
          <br />
          9. ADVERTISEMENTS:
          <br />
          <br />
          9.1.{' '}
        </strong>
        The Platform may contain third party advertisements. The display of such advertisements does not in any way
        imply an endorsement or recommendation by the Foundation of the relevant advertiser or the merchant, its
        products or services.
        <br />
        9.2. You are referred to the relevant advertiser or merchant for all information regarding the advertisement and
        its products and/or services. The Foundation accepts no responsibility for any interaction between you and the
        relevant third party and is released from any liability arising out of or in any way connected with such
        interaction.
        <strong>
          <br />
          <br />
          10. OWNERSHIP OF INTELLECTUAL PROPERTY:
          <br />
          <br />
          10.1.{' '}
        </strong>
        The Platform and all the rights including but not limited to intellectual property rights subsisting under or in
        relation to the Platform are owned by the Foundation and its affiliates, subsidiaries, licensors, etc. as the
        case may be. Nothing contained in this section shall be deemed to grant you any rights to the intellectual
        property rights contained in the Platform.
        <br />
        10.2. The Foundation respects copyright, and prohibits you from submitting, uploading, posting, or otherwise
        transmitting any content or details through the Platform that violates another person’s or entity’s proprietary
        or intellectual property rights.
        <br />
        10.3. If you believe that the Platform contains elements that infringe your intellectual property rights in your
        work, please notify the Foundation immediately. If we receive any intellectual property right infringement claim
        notification, The Foundation may remove all such content which is indicated as infringing and/or take any other
        appropriate action at its sole discretion.
        <br />
        10.4. All material on this Platform, including but not limited to information, data, charts, visual
        representations, audio, images, photographs, software, text, icons and such like (the “Platform Content”), are
        protected by copyright under the copyright laws or any other relevant intellectual property laws. You cannot use
        the Platform content, except as specified herein.
        <br />
        10.5. There may be proprietary logos, service marks and trademarks found on the Platform whether owned/used by
        us or otherwise. By displaying them on the Platform we are not granting you any license to utilize those
        proprietary logos, service marks, or trademarks. Any unauthorized use of the Platform content may violate
        copyright laws, trademark laws, the laws of privacy and publicity, and civil and criminal statutes.
        <br />
        10.6. You may choose to, or we may invite You or any third parties to submit or you may submit or take part in
        testimonials, blogs, photographs, content, whitepapers, comments, newsletters, reviews, ratings about the
        Services, including without limitation about how to improve the features of the Platform (“Feedback”). By
        submitting such Feedback, you agree that your disclosure is gratuitous, unsolicited and without restriction and
        will not place The Foundation under any fiduciary or other obligation to maintain the secrecy of such Feedback,
        and that we are free to use the Feedback for its business purposes, without any additional compensation to You,
        and/or to disclose the same on a non-confidential basis or otherwise to anyone. You further acknowledge that, by
        acceptance of your submission, The Foundation does not waive any rights to use similar or related ideas
        previously known to The Foundation or developed by its employees or obtained from sources other than You.
        <strong>
          <br />
          <br />
          11. CONFIDENTIALITY:
          <br />
          <br />
        </strong>
        11.1. Confidential information, for the purpose of these Terms, shall mean the information including but not
        limited to the features, functionalities, processes, data and information regarding the Platform, or other
        information of a confidential nature disclosed by one party to the other party under these Terms, in any form
        (“Confidential Information”). Confidential Information shall however, exclude any information which (i) is/ was
        publicly known or comes into public domain; (ii) is received by the receiving party from a third party, without
        breach of these Terms; (iii) was already in the possession of receiving party, without confidentiality
        restrictions, at the time of disclosure by the disclosing party; (iv) is permitted for disclosure by the
        disclosing party in writing; (v) independently developed by the receiving party without use of Confidential
        Information; (vi) is required to be disclosed by the receiving party pursuant to any order or requirement from a
        court, administrative or governmental agency. The receiving party agrees not to use any Confidential Information
        for any purpose except as stated in these Terms.
        <br />
        11.2. The Foundation will keep any personal information shared by you, confidential and only use it as per the
        terms of the Privacy Policy.
        <strong>
          <br />
          <br />
          12. PROHIBITED CONDUCT:
          <br />
          <br />
        </strong>
        12.1. You agree not to engage in any of the following activities:a) Violating laws and rights: You may not (i)
        use the Platform for any illegal purpose or in violation of any local, state, national, or international laws,
        (ii) violate or encourage others to violate any right of or obligation to a third party, including but not
        limited to, by infringing, misappropriating, or violating intellectual property, confidentiality, or privacy
        rights.b) Solicitation: You may not use the Platform, or any information provided through the Platform for the
        transmission of advertising or promotional materials, including junk mail, spam, chain letters, pyramid schemes,
        or any other form of unsolicited or unwelcome solicitation.c) Disruption: You may not use the Platform in any
        manner that could disable, overburden, damage, or impair the Platform, or the Services with any other party’s
        use and enjoyment of the Platform; by (i) uploading or otherwise disseminating any virus, adware, spyware, worm
        or other malicious code, or (ii) interfering with or disrupting any network, equipment, or server connected to
        or used to provide the Platform, or violating any regulation, policy, or procedure of any network, equipment, or
        server.d) Harming others: You may not share or transmit content or details that are harmful, offensive, obscene,
        abusive, invasive of privacy, defamatory, hateful or otherwise discriminatory, false or misleading, or which
        incite the commission of an illegal or violent act;e) Impersonation or unauthorized access: You will not
        impersonate another person or entity, or misrepresent Your affiliation with a person or entity when using the
        Platform; You will not use or attempt to use another’s User Account or personal information; and you will not
        attempt to gain unauthorized access to the Platform, through hacking, password mining or any other means.f)
        Developing competing offerings: You will not use the Platform to develop any competing platforms, applications,
        websites or products which are similar or substantially similar to the Platform. You understand and acknowledge
        that if you indulge in any of the prohibited conduct stated above, and it is brought to the notice of the
        Foundation, the Foundation may terminate your right to use the Platform and the Services and take any other
        corrective action as it deems fit. In the event, you are at the receiving end of any offensive content or are
        the victim of any such actions of any other Users, you are encouraged to report such content or conduct to the
        Foundation. The Foundation shall delete such offensive content and take any other corrective action as it deems
        fit.12.2. You understand and acknowledge that if you indulge in any of the prohibited conduct stated above, and
        it is brought to the notice of the Foundation, The Foundation may terminate your right to use the Platform and
        take any other corrective action as it deems fit.
        <strong>
          <br />
          <br />
          13. Non-Custodial and No Fiduciary Duties
          <br />
          <br />
        </strong>
        13.1. The Platform is a purely non-custodial application, meaning we do not ever have custody, possession, or
        control of your digital assets at any time. It further means you are solely responsible for the custody of the
        cryptographic private keys to the digital asset wallets you hold and you should never share your wallet
        credentials or seed phrase with anyone. We accept no responsibility for, or liability to you, in connection with
        your use of a wallet and make no representations or warranties regarding how any of our Platform will operate
        with any specific wallet. Likewise, you are solely responsible for any associated wallet and we are not liable
        for any acts or omissions by you in connection with or as a result of your wallet being compromised.
        <br />
        13.2. This Agreement is not intended to, and does not, create or impose any fiduciary duties on us. To the
        fullest extent permitted by law, you acknowledge and agree that we owe no fiduciary duties or liabilities to you
        or any other party, and that to the extent any such duties or liabilities may exist at law or in equity, those
        duties and liabilities are hereby irrevocably disclaimed, waived, and eliminated. You further agree that the
        only duties and obligations that we owe you are those set out expressly in this Agreement.
        <strong>
          <br />
          <br />
          14. DISCLAIMER OF WARRANTIES:
          <br />
          <br />
          14.1.{' '}
        </strong>
        INVESTMENTS IN VIRTUAL DIGITAL ASSETS ARE HIGHLY SPECULATIVE. YOU ARE SOLELY RESPONSIBLE FOR EVALUATING THE
        MERITS AND RISKS ASSOCIATED WITH THE USE OF ANY INFORMATION, MATERIALS, CONTENT, USER CONTENT, OR THIRD-PARTY
        CONTENT PROVIDED THROUGH THE SERVICE BEFORE MAKING ANY DECISIONS BASED ON SUCH CONTENT. AS A USER OF THE
        SERVICE, YOU ALONE ARE RESPONSIBLE FOR DETERMINING WHETHER ANY CONTENT, PRODUCT, SERVICE, VIRTUAL DIGITAL ASSET,
        SECURITY, OR OTHER FINANCIAL INSTRUMENT MENTIONED ON THE PLATFORM, SERVICE IS SUITABLE FOR YOUR PERSONAL
        FINANCIAL PURPOSES. PAST PERFORMANCE DATA, PERFORMANCE GRAPHS OR OTHER HISTORICAL INFORMATION ARE NOT A
        GUARANTEE OF FUTURE PERFORMANCE AND SHOULD NOT BE CONSTRUED AS INDICATIVE OF FUTURE RESULTS. YOU SHOULD CONSIDER
        YOUR INDIVIDUAL INVESTMENT OBJECTIVES AND RISK TOLERANCE BEFORE INVESTING. THE CONTENT PRESENTED ON THE SERVICE
        IS NOT INTENDED TO PROVIDE YOU OR ANYONE ELSE WITH INVESTMENT, LEGAL, TAX, INSURANCE OR ANY OTHER KIND OF
        PROFESSIONAL ADVICE. YOU SHOULD CONSULT WITH A FINANCIAL, TAX OR LEGAL PROFESSIONAL FOR ADVICE REGARDING YOUR
        SPECIFIC FINANCIAL, TAX OR LEGAL POSITIONS AND CIRCUMSTANCES.
        <br />
        14.2. THE PLATFORM IS MERELY A TECHNOLOGY PLATFORM AND YOU ARE SOLELY AND ENTIRELY RESPONSIBLE FOR THE LEGAL,
        REGULATORY AND TAX COMPLIANCE OF ALL TRANSACTIONS CARRIED OUT BY YOU USING THE PLATFORM OR SERVICES.14.3. YOU
        EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE PLATFORM AND THE SERVICES PROVIDED THROUGH IT IS ENTIRELY AT
        YOUR OWN RISK AND THAT THE PLATFORM AND THE INFORMATION THEREIN ARE PROVIDED ON AN "AS IS" OR "AS AVAILABLE"
        BASIS, WITHOUT ANY WARRANTIES OF ANY KIND. ALL EXPRESS AND IMPLIED WARRANTIES, INCLUDING, WITHOUT LIMITATION,
        THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS
        ARE EXPRESSLY DISCLAIMED TO THE FULLEST EXTENT PERMITTED BY LAW. TO THE FULLEST EXTENT PERMITTED BY LAW, THE
        FOUNDATION, ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN
        CONNECTION WITH THE PLATFORM, THE SERVICES AND YOUR USE THEREOF. THE FOUNDATION MAKES NO WARRANTIES OR
        REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES OR ANY INFORMATION PROVIDED OR THE PLATFORM'S
        CONTENT OR THE DATA SHARED BY OR THE CONTENT OF ANY THIRD PARTY LINKED TO THE PLATFORM AND ASSUMES NO LIABILITY
        OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT, (II) PERSONAL INJURY OR PROPERTY
        DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE PLATFORM AND THE PRODUCTS, (III)
        ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL
        INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE PLATFORM, (V) ANY
        BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE PLATFORM BY ANY THIRD
        PARTY, AND/OR (VI) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A
        RESULT OF THE USE OF ANY SERVICES MADE AVAILABLE VIA MIMIK FINANCE. THE FOUNDATION DOES NOT WARRANT, ENDORSE,
        GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY INFORMATION ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE
        PLATFORM OR ANY HYPERLINKED WEBSITE OR FEATURED IN ANY BANNER OR OTHER ADVERTISING.15. LIMITATION OF
        LIABILITY:IN NO EVENT SHALL THE FOUNDATION, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, BE LIABLE FOR ANY
        DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES (EVEN IF THE FOUNDATION HAS BEEN
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM ANY ASPECT OF YOUR USE OF THE PLATFORM OR THE
        INFORMATION PROVIDED THROUGH IT, INCLUDING WITHOUT LIMITATION WHETHER THE DAMAGES ARISE FROM USE OR MISUSE OF
        THE PLATFORM OR THE SERVICES, FROM INABILITY TO USE THE PLATFORM OR THE SERVICES, OR THE INTERRUPTION,
        SUSPENSION, MODIFICATION, ALTERATION, OR TERMINATION OF THE PLATFORM OR THE SERVICES PROVIDED THROUGH IT. SUCH
        LIMITATION OF LIABILITY SHALL ALSO APPLY WITH RESPECT TO DAMAGES INCURRED BY REASON OF OTHER SERVICES RENDERED
        THROUGH OR ADVERTISED IN CONNECTION WITH THE PLATFORM OR THE SERVICES OR ANY LINKS ON THE PLATFORM. THESE
        LIMITATIONS SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW. YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT THE
        FOUNDATION SHALL NOT BE LIABLE FOR YOUR ILLEGAL CONDUCT OF ANY OTHER USER OR THIRD PARTY AND THAT THE RISK OF
        HARM OR DAMAGE FROM THE FOREGOING RESTS ENTIRELY WITH YOU. THE FOREGOING LIMITATIONS OF LIABILITY WILL APPLY
        NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED WARRANTY OR REMEDY HEREIN. YOUR SOLE AND
        EXCLUSIVE REMEDY FOR DISSATISFACTION WITH THE SERVICE IS TO STOP USING THE PLATFORM OR THE SERVICE.
        <strong>
          <br />
          <br />
          16. INDEMNIFICATION:
          <br />
          <br />
        </strong>
        You agree to indemnify us and hold us harmless from and against any claims arising out of or relating to: (a)
        infringement of any third- party rights including without limitation any intellectual property rights; (b) any
        breach or violation of these Terms; (c) any breach of applicable laws, rules, regulations and/ or policies; (d)
        your negligence or omission (including any action or inaction); (e) any User conduct or any other information or
        content that is submitted through your User Account including without limitation misleading, false, incomplete
        or inaccurate information.
        <br />
        <br />‍
        <strong>
          17. TERMINATION:
          <br />
          <br />
        </strong>
        17.1. The Foundation reserves the right to terminate your right and access to use the Platform with or without
        any reason whatsoever. Additionally, your right to access and use the Platform terminates automatically upon
        your material breach of these Terms.
        <br />
        17.2. Survival: All clauses which by nature including without limitation the Disclaimer of Warranties, the
        Limitation of Liability, Indemnity and the Jurisdiction provisions will survive any termination of these Terms.
        <br />
        <strong>
          <br />
          18. MISCELLANEOUS TERMS:
          <br />
          <br />
        </strong>
        18.1. Dispute Resolution, Choice of Law and Jurisdiction: a) We will use our best efforts to resolve any
        potential disputes through informal, good faith negotiations. If a potential dispute arises, you must contact us
        by sending an email to support@Mimik.finance so that we can attempt to resolve it without resorting to formal
        dispute resolution. If we aren't able to reach an informal resolution within sixty days of your email, then you
        and we both agree to resolve the potential dispute according to the process set forth below.b) These Terms shall
        be construed and governed by the laws of Panama. c) Any dispute arising, between you and us shall be shall be
        finally and exclusively settled by arbitration administered by the Singapore International Arbitration Centre
        (“SIAC”) under the SIAC Arbitration Rules in force at the time of the filing for arbitration of such dispute.d)
        The arbitration shall be held on a confidential basis before a single arbitrator, who shall be selected by us
        pursuant to SIAC rules. The arbitration will be held at a place selected by us unless you and we both agree to
        hold it elsewhere. Unless we agree otherwise, the arbitrator may not consolidate your claims with those of any
        other party. Any judgment on the award rendered by the arbitrator may be entered in any court of competent
        jurisdiction. If for any reason a claim by law or equity must proceed in court rather than in arbitration you
        agree to waive any right to a jury trial and any claim may be brought only in a court located in Panama.e) Class
        Action and Jury Trial Waiver: You must bring any and all disputes against us in your individual capacity and not
        as a plaintiff in or member of any purported class action, collective action, private attorney general action,
        or other representative proceeding. This provision applies to class arbitration. You and we both agree to waive
        the right to demand a trial by jury.18.2. No waiver: The Foundation’s failure to insist on or enforce strict
        performance of any of these Terms shall not be construed as a waiver of any provision or right.18.3.
        Severability: If any part of these Terms is held to be invalid or unenforceable by any law or regulation or
        final determination of a competent court or tribunal, that provision shall be deemed severable and will not
        affect the validity and enforceability of the remaining provisions.18.4. No agency relationship: You agree that
        no joint venture, employment, or agency relationship exists between you and The Foundation as a result of these
        Terms or due to your use of the Platform.18.5. Entire Agreement: These Terms and the Privacy Policy constitute
        the entire agreement between you and The Foundation relating to this subject matter and supersede any and all
        prior communications and/or agreements between you and The Foundation relating to this subject matter.18.6.
        Force Majeure: Without limiting the foregoing, under no circumstances shall The Foundation be held liable for
        any damage or loss due to deficiency in the performance of the Platform resulting directly or indirectly from
        acts of nature, forces, or causes beyond its reasonable control, including, without limitation, internet
        failures, computer equipment failures, telecommunication equipment failures, cyber incidents beyond our control,
        &nbsp; change in laws and regulations, or any other government regulations, floods, storms, electrical failure,
        civil disturbances, riots, lockdowns, pandemics or any other similar events. Notwithstanding the foregoing, you
        will be liable for any obligation towards any fees, payments and any other payable amounts to the Foundation.
        <br />
        <strong>
          <br />
          19. ELECTRONIC RECORD:
          <br />
          <br />
        </strong>
        This document is an electronic record. This electronic record is generated by a computer system and does not
        require any physical or digital signatures.
        <br />
        <strong>
          <br />
          20. CHANGE IN TERMS:
          <br />
          <br />
        </strong>
        The Foundation may update these Terms without notice to you. You are encouraged to check these Terms on a
        regular basis to be aware of the changes made to them. Your continued use of the Platform after such change
        shall be deemed to be your acceptance of the revised Terms.
        <br />
        <strong>
          <br />
          21. GRIEVANCE REDRESSAL
          <br />‍<br />
        </strong>
        21.1. Mimik Finance offers an email-based support system. In case You require any assistance or support, you may
        access support resources or contact our support by emailing at support@Mimik.finance.
        <br />
        21.2. The User agrees and acknowledges that Mimik Finance shall address and attempt to resolve the complaint
        received in accordance with the standard policies and procedures adopted by the Foundation, the User’s
        disapproval/discontent with the outcome/mode of redressal shall not be deemed to mean non-redressal of the
        complaint by the Foundation.
      </TermsContent>
    </TermsContainer>
  );
};

const TermsContainer = styled(Box)(({ theme }) => ({
  maxWidth: '668px',
  marginBottom: '72px',
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingBottom: '0',
  paddingTop: '100px'
}));

const TermsContent = styled(Box)(({ theme }) => ({
  color: '#d5d4d4',
  textAlign: 'justify',
  marginLeft: '0',
  marginRight: '0',
  paddingLeft: '0',
  fontWeight: '400',
  fontSize: '18px'
}));

const TermsTitle = styled(Box)(({ theme }) => ({
  fontSize: '54px',
  textAlign: 'center',
  fontWeight: '700'
}));
