import { Box, Stack, Link, Container } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { styled } from '@mui/system'
import { SimplifyImg1, SimplifyImg2, SimplifyImg3, SimplifyImg4, SimplifyImg5 } from 'src/config/images'
import AOS from 'aos'
import { useEffect } from 'react'

const Img = ({ imgSrc, alt }: { imgSrc: string, alt: string }) => {
  return (
    <Box sx={{ width: { md: '40%' } }}>
      <Stack justifyContent='center' alignItems='center' sx={{ height: '100%' }}>
        <img src={imgSrc} style={{ maxWidth: 300 }} alt={alt} />
      </Stack>
    </Box>
  )
}

const CtaButtonComponent = ({ buttonLabel, href }: { buttonLabel: string, href: string }) => {
  return (
    <Link href={href} sx={{ textDecoration: 'none' }} target='_blank'>
      <CtaButton mt={3}>
        {buttonLabel}
        <ArrowForwardIcon fontSize="small" />
      </CtaButton>
    </Link>
  )
}

const Symplifying = () => {
  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])

  return (
    <SymplifyingContainer component='section'>
      <SymplifyingHeading pb={9}>
        <ProtocolsTitle mb={4}>
          Simplifying Crypto Trading
        </ProtocolsTitle>
        <Box sx={{ padding: '0 15%', textAlign: 'center', fontSize: '20px', fontWeight: 400 }}>
          We serve as the gateway for users to effortlessly deposit liquidity into vaults that mirror and scale the portfolios of seasoned traders on our platform.
        </Box>
      </SymplifyingHeading>
      <SimplifyingCard>
        <SimplifyingCardContainer>
          <SimplifyingCardRow direction={{ xs: 'column', md: 'row' }}>
            <DualVaultContainer justifyContent='center'>
              <SimplifyCardTitle>Dual Vault Model</SimplifyCardTitle>
              <SimplifyCardContent>Mimik uses a dual vault synchronized investment strategy orchestration (SISO), which is a significantly evolved copy trading model that does not allow the pooling of funds.</SimplifyCardContent>
              <CtaButtonComponent buttonLabel='Explore Vaults' href='https://beta.mimik.finance' />
            </DualVaultContainer>
            <Img imgSrc={SimplifyImg3} alt='dual vault model' />
          </SimplifyingCardRow>
        </SimplifyingCardContainer>
      </SimplifyingCard>
      <SimplifyingCard >
        <SimplifyingCardContainer>
          <SimplifyingCardRow direction={{ xs: 'column-reverse', md: 'row' }}>
            <Img imgSrc={SimplifyImg1} alt='Multi-Asset Vaults' />
            <DualVaultContainer justifyContent='center' alignItems={{ xs: 'start', md: 'end' }}>
              <SimplifyCardTitle>Multi-Asset Vaults</SimplifyCardTitle>
              <SimplifyCardContent sx={{ textAlign: { xs: 'left', md: 'right' } }}>Mimik's social trading mirrors the complete portfolio and supports effective Multi-Asset Vault approaches with no socialization of returns. This diversification helps mitigate losses in any single asset from impacting your entire portfolio significantly.</SimplifyCardContent>
              <CtaButtonComponent buttonLabel='Explore Vaults' href='https://beta.mimik.finance' />
            </DualVaultContainer>
          </SimplifyingCardRow>
        </SimplifyingCardContainer>
      </SimplifyingCard>
      <SimplifyingCard >
        <SimplifyingCardContainer>
          <SimplifyingCardRow direction={{ xs: 'column', md: 'row' }}>
            <DualVaultContainer justifyContent='center'>
              <SimplifyCardTitle>
                Deep Liquidity
                <Box ml={2} px={2} py={0.5} sx={{ fontSize: { xs: 11, md: 14 }, color: '#063846', backgroundColor: '#1CE6F4', borderRadius: 25 }}>
                  Coming Soon
                </Box>
              </SimplifyCardTitle>
              <SimplifyCardContent>Tap into aggregated liquidity from major perpetual and spot exchanges across Arbitrum that ensures trade execution with minimal slippage.</SimplifyCardContent>
              <CtaButtonComponent buttonLabel='Create a Vault' href='#' />
            </DualVaultContainer>
            <Img imgSrc={SimplifyImg2} alt='Deep Liquidity' />
          </SimplifyingCardRow>
        </SimplifyingCardContainer>
      </SimplifyingCard>
      <SimplifyingCard >
        <SimplifyingCardContainer>
          <SimplifyingCardRow direction={{ xs: 'column-reverse', md: 'row' }}>
            <Img imgSrc={SimplifyImg4} alt='Earn Fees' />
            <DualVaultContainer justifyContent='center' alignItems={{ xs: 'start', md: 'end' }}>
              <SimplifyCardTitle>
                <Box mr={2} px={2} py={0.5} sx={{ fontSize: { xs: 11, md: 14 }, color: '#063846', backgroundColor: '#1CE6F4', borderRadius: 25 }}>
                  Coming Soon
                </Box>
                Earn Fees
              </SimplifyCardTitle>
              <SimplifyCardContent sx={{ textAlign: { xs: 'left', md: 'right' } }}>Earn fees by creating a vault and generating returns for the users with an accesss to multiple exchanges and liquidity sources including Uniswap, GMX, Vertex and Hyperliquid in one unified platform.</SimplifyCardContent>
              <CtaButtonComponent buttonLabel='Learn More' href='#' />
            </DualVaultContainer>
          </SimplifyingCardRow>
        </SimplifyingCardContainer>
      </SimplifyingCard>
      <SimplifyingCard >
        <SimplifyingCardContainer>
          <SimplifyingCardRow direction={{ xs: 'column', md: 'row' }}>
            <DualVaultContainer justifyContent='center'>
              <SimplifyCardTitle>
                Bidirectional Trades
                <Box ml={2} px={2} py={0.5} sx={{ fontSize: { xs: 11, md: 14 }, color: '#063846', backgroundColor: '#1CE6F4', borderRadius: 25 }}>
                  Coming Soon
                </Box>
              </SimplifyCardTitle>
              <SimplifyCardContent>Execute both long and short trades, optimizing opportunities for profit and risk management.</SimplifyCardContent>
              <CtaButtonComponent buttonLabel='Create a Vault' href='#' />
            </DualVaultContainer>
            <Img imgSrc={SimplifyImg5} alt='Bidirectional Trades' />
          </SimplifyingCardRow>
        </SimplifyingCardContainer>
      </SimplifyingCard>
    </SymplifyingContainer>
  )
}

export default Symplifying

const SymplifyingContainer = styled(Box)(({ theme }) => ({
  color: '#F0F9FF',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '40px',
  position: 'relative',
  [theme.breakpoints.down(768)]: {
    height: 'auto',
    marginBottom: '98px'
  }
}))

const ProtocolsTitle = styled(Box)(({ theme }) => ({
  fontSize: '48px',
  fontWeight: '700',
  textAlign: 'center',
  span: {
    color: '#FFC529'
  },
  [theme.breakpoints.down(768)]: {
    fontSize: '38px'
  }
}))

const SymplifyingHeading = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '50vh',
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  flexDirection: 'column',
  position: 'relative',
  [theme.breakpoints.down(768)]: {
    gap: '10px',
    height: 'auto'
  }
}))

const SimplifyingCard = styled('nav')(({ theme }) => ({
  width: '100%',
  height: '50vh',
  background: 'linear-gradient(180deg, rgba(99, 245, 253, 0.1) 0%, rgba(153, 153, 153, 0) 100%)',
  [theme.breakpoints.down(540)]: {
    height: 'auto'
  }
}))

const SimplifyingCardContainer = styled(Container)(({ theme }) => ({
  height: '100%',
  [theme.breakpoints.down(540)]: {
    paddingTop: 16
  }
}))

const SimplifyingCardRow = styled(Stack)(({ theme, direction }) => ({
  height: '100%',
  justifyContent: 'space-between',
  [theme.breakpoints.down(540)]: {
    gap: 40
  }
}))

const DualVaultContainer = styled(Stack)(({ theme }) => ({
  height: '100%',
  width: '50%',
  [theme.breakpoints.down(540)]: {
    width: '100%'
  }
}))

const CtaButton = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  fontSize: 20,
  fontWeight: 500,
  color: '#1CE6F4',

  '& svg': {
    marginLeft: 12
  }
}))

const SimplifyCardTitle = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  fontSize: 32,
  fontWeight: 700,
  marginBottom: 16,
  [theme.breakpoints.down(768)]: {
    fontSize: 23
  }
}))

const SimplifyCardContent = styled(Box)(() => ({
  fontSize: 16,
  fontWeight: 400
}))
