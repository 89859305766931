import { useEffect } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import {
  DiscordFillSvg,
  DiscordRealSvg,
  InstagramFillSvg,
  InstagramRealSvg,
  IconMimikFillPng,
  IconMimikRealPng,
  LinkedinFillSvg,
  LinkedinRealSvg,
  MediumFillSvg,
  RedditFillSvg,
  RedditRealSvg,
  TelegramFillSvg,
  TelegramRealSvg,
  TwitterFillSvg,
  TwitterRealSvg,
  YoutubeFillSvg,
  YoutubeRealSvg
} from 'src/config/images';
import { Container } from 'src/components/Container';

export const Community = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);
  const communityItemData = [
    {
      id: 1,
      title: 'Instagram',
      content: 'Follow us on our Instagram to stay updated with crypto Marke',
      img: InstagramFillSvg,
      hoverImg: InstagramRealSvg,
      link: 'https://www.instagram.com/mimikfinance/'
    },
    {
      id: 2,
      title: 'Twitter',
      content: "Follow us on Twitter to stay updated with what's happening in web3",
      img: TwitterFillSvg,
      hoverImg: TwitterRealSvg,
      link: 'https://twitter.com/mimikfinance'
    },
    {
      id: 3,
      title: 'Discord',
      content: 'Collaborate, contribute and share your ideas on our discord server',
      img: DiscordFillSvg,
      hoverImg: DiscordRealSvg,
      link: 'https://discord.gg/jvXqHYyA'
    },
    {
      id: 4,
      title: 'Telegram',
      content: 'Join our Telegram to become early adopters of Mimik Finance',
      img: TelegramFillSvg,
      hoverImg: TelegramRealSvg,
      link: 'https://t.me/mimikfinance'
    },
    {
      id: 5,
      title: 'Reddit',
      content: 'Share and participate in discussions in and around Web3',
      img: RedditFillSvg,
      hoverImg: RedditRealSvg,
      link: 'https://www.reddit.com/r/mimikfinance/'
    },
    {
      id: 6,
      title: 'YouTube',
      content: 'Subscribe to our Youtube channel to watch daily and weekly crypto market updates',
      img: YoutubeFillSvg,
      hoverImg: YoutubeRealSvg,
      link: 'https://www.youtube.com/@mimikfinance'
    },
    {
      id: 7,
      title: 'LinkedIn',
      content: 'Follow our LinkedIn page to get product-related announcements',
      img: LinkedinFillSvg,
      hoverImg: LinkedinRealSvg,
      link: 'https://www.linkedin.com/company/mimikfinance/'
    },
    {
      id: 8,
      title: 'Medium',
      content: "Stay informed about Mimik Finance's progress and updates through Medium articles",
      img: MediumFillSvg,
      hoverImg: MediumFillSvg,
      link: 'https://medium.com/@mimikfinance'
    }
  ];
  return (
    <Container>
      <CommunityWrapper mb={24}>
        <CommunityContainer>
          <CommunityText>
            <CommunitySlogan>Join Our Worldwide Community</CommunitySlogan>
          </CommunityText>
          <CommunityItemContainer>
            {communityItemData.map((item) => (
              <CommunityItem
                img={item.img}
                hoverImg={item.hoverImg}
                title={item.title}
                content={item.content}
                link={item.link}
                key={item.id}
              />
            ))}
          </CommunityItemContainer>
        </CommunityContainer>
      </CommunityWrapper>
    </Container>
  );
};

const CommunityWrapper = styled(Box)(({ theme }) => ({
  paddingTop: '100px',
  display: 'flex',
  color: '#EBFFFF',
  justifyContent: 'center'
}));

const CommunityContainer = styled(Box)(({ theme }) => ({
  display: 'row',
  width: '100%',
  [theme.breakpoints.down(1180)]: {
    flexDirection: 'column',
    gap: '80px',
    width: 'inherit'
  },
  [theme.breakpoints.down(768)]: {
    gap: '60px'
  }
}));

const CommunityText = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: '56px',
  [theme.breakpoints.down(1180)]: {
    maxWidth: '640px'
  }
}));

const CommunitySlogan = styled(Box)(({ theme }) => ({
  fontSize: '48px',
  fontWeight: '600',
  [theme.breakpoints.down(768)]: {
    fontSize: '36px'
  }
}));

const CommunityItemContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: '20px',
  [theme.breakpoints.down(768)]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '40px'
  }
}));

interface CommunityItemProps {
  img: string;
  hoverImg: string;
  title: string;
  content: string;
  link: string;
}

const CommunityItem = (props: CommunityItemProps) => {
  const { img, hoverImg, title, link } = props;
  return (
    <ALink href={link} target="_blank" rel="noopenner noreferrer">
      <CommunityItemCard hoverimg={hoverImg}>
        <Img src={img} alt="item-img" />
        {title}
      </CommunityItemCard>
    </ALink>
  );
};

const CommunityItemCard = styled(Box)<{ hoverimg: string }>(({ theme, hoverimg }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '12px',
  background: '#06384659',
  alignItems: 'center',
  gap: '15px',
  fontSize: '24px',
  cursor: 'pointer',
  fontWeight: '600',
  paddingTop: '32px',
  paddingBottom: '32px',
  '&:hover': {
    img: {
      content: `url(${hoverimg})`
    },
    color: '#1CE6F4'
  }
}));

const Img = styled('img')(({ theme }) => ({
  width: '42px',
  height: '42px'
}));

const ALink = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  color: '#FFF'
}));
