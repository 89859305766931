import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import { styled } from '@mui/system'
import HeroBanner from './hero'
import FeaturedIn from './featuredin'
import Symplifying from './simplifying'
import OnePlatform from './oneplatform'
import Protocols from './protocols'
import OurProducts from './products'
import ScrollTop from './scrollTop'

export const Home = () => {
  const ref = useRef<Element | null>(null)
  const navigate = useNavigate()
  const [isDisplayScrollTop, setIsDisplayScrollTop] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [navigate])

  const pop = () => {
    if (window.scrollY <= 890 && isDisplayScrollTop) {
      return setIsDisplayScrollTop(false)
    }
    if (window.scrollY > 890 && !isDisplayScrollTop) {
      return setIsDisplayScrollTop(true)
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', pop)

    return () => window.removeEventListener('scroll', pop)
  }, [isDisplayScrollTop])

  return (
    <HomeContainer ref={ref}>
      <HeroBanner />
      <OurProducts />
      <Protocols />
      <Symplifying />
      <OnePlatform />
      <FeaturedIn />
      <ScrollTop isDisplay={isDisplayScrollTop} />
    </HomeContainer>
  )
}

const HomeContainer = styled(Box)(({ theme }) => ({
  scrollBehavior: 'smooth',
  'section': {
    scrollSnapAlign: 'start'
  },
  [theme.breakpoints.down(768)]: {
    'section': {
      scrollSnapAlign: 'inherit'
    }
  }
}))
