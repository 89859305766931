import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { AnuragDixitImg, DmitriyImg, HarivadanImg, SangamImg, LinkedinSvg, VivekImg } from 'src/config/images';

export const LeaderShip = () => {
  const founders = [
    {
      id: 1,
      name: 'Anurag D.',
      role: 'Founder & CEO',
      linkedin: 'https://www.linkedin.com/in/anuragdixit/',
      avatar: AnuragDixitImg
    },
    {
      id: 2,
      name: 'Vivekanand P.',
      role: 'Co-Founder & COO',
      linkedin: '',
      avatar: VivekImg
    },
    {
      id: 3,
      name: 'Harivadan P.',
      role: 'Co-Founder',
      linkedin: 'https://www.linkedin.com/in/haripandya/',
      avatar: HarivadanImg
    },
    {
      id: 4,
      name: 'Dmitriy C.',
      role: 'VP Engineering',
      linkedin: 'https://www.linkedin.com/in/dmitriy-c-7b605016/',
      avatar: DmitriyImg
    },
    {
      id: 5,
      name: 'Sangam B.',
      role: 'Chief of Staff',
      linkedin: 'https://www.linkedin.com/in/sangambharti/',
      avatar: SangamImg
    }
  ];
  return (
    <LeaderShipContainer>
      <LeaderShipTitle>Leadership</LeaderShipTitle>
      <FoundersContainer>
        {founders.map((founder) => (
          <FounderItem
            avatar={founder.avatar}
            name={founder.name}
            role={founder.role}
            linkedin={founder.linkedin}
            key={founder.id}
          />
        ))}
      </FoundersContainer>
    </LeaderShipContainer>
  );
};

const LeaderShipContainer = styled('nav')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
  position: 'relative'
}));

const LeaderShipTitle = styled(Box)(({ theme }) => ({
  fontSize: '54px',
  fontWeight: '600',
  [theme.breakpoints.down(640)]: {
    fontSize: '45px'
  }
}));

const FoundersContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(5, 1fr)',
  columnGap: '20px',
  rowGap: '60px',
  [theme.breakpoints.down(1076)]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '20px'
  }
}));

interface FounderItemProps {
  avatar: string;
  name: string;
  role: string;
  linkedin: string;
}

const FounderItem = (props: FounderItemProps) => {
  const { avatar, name, role, linkedin } = props;
  return (
    <FounderItemContainer>
      <FounderAvatar src={avatar} alt="founder-avatar" />
      <FounderInfoContainer>
        <FounderName>{name}</FounderName>
        <FounderRole>{role}</FounderRole>
      </FounderInfoContainer>
      {linkedin !== '' ? (
        <ALink href={linkedin} target="_blank" rel="noopenner noreferrer">
          <img src={LinkedinSvg} alt="linkedin-svg" />
        </ALink>
      ) : (
        <ALink>
          <img src={LinkedinSvg} alt="linkedin-svg" />
        </ALink>
      )}
    </FounderItemContainer>
  );
};

const FounderItemContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '30px 20px',
  border: '1px solid #1CE6F4',
  borderRadius: '20px',
  gap: '10px'
}));

const FounderAvatar = styled('img')(({ theme }) => ({
  borderRadius: '50%',
  width: '80px',
  height: '80px',
  objectFit: 'cover',
  [theme.breakpoints.down(540)]: {
    width: '120px',
    height: '120px'
  }
}));

const ALink = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  borderRadius: '50%',
  cursor: 'pointer',
  [theme.breakpoints.down(1370)]: {
    width: '25px',
    height: '25px'
  },
  '& img': {
    width: '35px',
    height: '35px'
  }
}));

const FounderName = styled(Box)(({ theme }) => ({
  fontSize: '24px',
  fontWeight: '600',
  textAlign: 'center',
  [theme.breakpoints.down(768)]: {
    fontSize: '18px'
  }
}));

const FounderRole = styled(Box)(({ theme }) => ({
  fontSize: '18px',
  color: '#00C8D9',
  textAlign: 'center',
  [theme.breakpoints.down(768)]: {
    fontSize: '14px'
  }
}));

const FounderInfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '7px'
}));
