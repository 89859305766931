import { Box, Stack } from '@mui/material'
import { styled } from '@mui/system'
import Link from "@mui/material/Link";

export const Mission = () => {
  return (
    <OurMissionContainer>
      <OurMissionTitleContainer>
        <OurMissionTitle>OUR VISION</OurMissionTitle>
      </OurMissionTitleContainer>
      <AboutContainer>
        <AboutContent>
          <AboutcontentText>
            At Mimik Finance, our vision is to revolutionize social trading for the next wave of users entering the Web3 ecosystem. We are committed to empowering millions of individuals who are not proficient in trading by providing them with seamless access to expert strategies while ensuring full control of their assets.
            <br />
            <br />
            We envision a future where every individual, regardless of their trading expertise, can participate in the exciting world of decentralized finance. Mimik Finance serves as the gateway for users to effortlessly deposit liquidity into vaults that mirror and scale the portfolios of seasoned traders on our platform.
            <br />
            <br />
            Our platform is accessible across all chains including EVM and BRC-20, offering users unparalleled speed, efficiency, and transparency. Integrated with multiple spot and derivatives DEXes, Mimik Finance provides hassle-free trading experiences to traders, while non-traders can easily replicate expert strategies with just one click.
            <br />
            <br />
            Our copy trading mechanism enables expert traders to seamlessly manage portfolio services for novice users without encountering regulatory hurdles. With SISO (Synchronized Investment Strategy Orchestration), users can deposit funds into vaults, automatically replicating and scaling expert traders' strategies in real-time, while maintaining complete control over their assets. Our dual-vault mechanism eliminates regulatory ambiguities associated with fund pooling, offering users peace of mind and regulatory clarity.
          </AboutcontentText>
        </AboutContent>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 4 }}>
          <CtaButton href={'https://beta.mimik.finance/strategy'} target='_blank'>
            Explore Vaults
          </CtaButton>
          <CtaButton href={'#'}>
            Create a Vault
          </CtaButton>
        </Stack>
      </AboutContainer>
    </OurMissionContainer>
  )
}

const OurMissionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
  position: 'relative'
}))

const OurMissionTitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}))

const OurMissionTitle = styled(Box)(({ theme }) => ({
  fontSize: '48px',
  fontWeight: '700',
  [theme.breakpoints.down(640)]: {
    fontSize: '45px'
  }
}))

const AboutContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '60px'
}));

const AboutContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '15px',
  [theme.breakpoints.down(840)]: {
    flexDirection: 'column',
    gap: '30px'
  }
}));

const AboutcontentText = styled(Box)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: 400,
  padding: '46px 32px',
  border: '1px solid #145465',
  borderRadius: '12px',
  [theme.breakpoints.down(540)]: {
    fontSize: '14px'
  }
}));

const CtaButton = styled(Link)(({ theme }) => ({
  padding: '8px 32px',
  border: '2px solid #1CE6F4',
  overflow: 'hidden',
  fontSize: '18px',
  borderRadius: '12px',
  textTransform: 'inherit',
  textDecoration: 'none',
  transition: '.3s ease-in-out 0s',
  backgroundColor: '#00c8d9',
  '&:hover': {
    color: '#063846',
    backgroundColor: '#0090d1',
    transform: 'scale(1.12)'
  },
  '&:active': {
    backgroundColor: '#0090d1'
  }
}))
