import { ToastContainer } from 'react-toastify'
import { Header } from './header'
import { Footer } from './footer'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { scrollToId } from 'src/utils/utils'

interface LayoutProps {
  children: React.ReactNode
}

export const Layout = ({ children }: LayoutProps) => {
  const location = useLocation()

  useEffect(() => {
    if (!location.hash) return

    window.requestAnimationFrame(() => {
      scrollToId(location.hash)
    })
  }, [location.hash])

  return (
    <div style={{ overflow: location.pathname === '/faqs' ? 'unset' : 'inherit' }}>
      <Header />
      {children}
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        theme="light"
      />
      <Footer />
    </div>
  )
}
