/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useState } from 'react'
import { Box, Button, IconButton } from '@mui/material'
import { styled } from '@mui/system'
import { Container } from 'src/components/Container'
import { KunjiLogoPng } from 'src/config/images'
import { useNavigate, useLocation } from 'react-router-dom'
import { Menu } from '@mui/icons-material'
import { NavModal } from 'src/components/Modal/nav-modal'
import ArrowOutward from '@mui/icons-material/ArrowOutward'

export const Header = () => {
  const navigate = useNavigate()
  const [isNavOpen, setNavOpen] = useState(false)
  const location = useLocation()

  return (
    <Container>
      <HeaderWrapper id='header'>
        <HeaderContainer>
          <KunjiLogo src={KunjiLogoPng} alt="kunji-logo" onClick={() => navigate('/')} />
          <TabContainer>
            <TabBar onClick={() => navigate('/#products')}>
              Products
            </TabBar>
            <TabBar active={location.pathname === '/about-us' ? 1 : 0} onClick={() => navigate('/about-us')}>
              About Us
            </TabBar>
            <TabBar active={location.pathname === '/community' ? 1 : 0} onClick={() => navigate('/community')}>
              Community
            </TabBar>
            <TabBar active={location.pathname === '/docs' ? 1 : 0} onClick={() => navigate('/docs')}>
              Docs
            </TabBar>
          </TabContainer>
          <a
            href='https://beta.mimik.finance/'
            target='_blank'
            style={{ textDecoration: 'none' }}
            rel="noopenner noreferrer"
          >
            <LaunchBetaButton>
              Launch Dapp
              <ArrowOutward fontSize='small' />
            </LaunchBetaButton>
          </a>
          <MobileMenuButton onClick={() => setNavOpen(true)}>
            <Menu />
          </MobileMenuButton>
        </HeaderContainer>
      </HeaderWrapper>
      <NavModal isState={isNavOpen} setState={setNavOpen} />
    </Container>
  );
};

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '30px',
  background: '#07314A24',
  border: '1px solid #FFFFFF0D',
  borderRadius: '12px',
  padding: '15px 12px',
  width: '100%'
}));

const KunjiLogo = styled('img')(({ theme }) => ({
  width: '125px',
  height: 'auto',
  cursor: 'pointer'
}));

const TabContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '32px',
  [theme.breakpoints.down(1160)]: {
    display: 'none'
  }
}));

const TabBar = styled(Box)<{ active?: number }>(({ theme, active }) => ({
  color: active === 1 ? '#1CE6F4' : '#667085',
  fontSize: '16px',
  cursor: 'pointer',
  fontWeight: active === 1 ? '600' : '400',
  '&:hover': {
    color: '#1CE6F4'
  }
}));

const LaunchBetaButton = styled(Button)(({ theme }) => ({
  width: '170px',
  height: '40px',
  textTransform: 'capitalize',
  backgroundColor: '#1CE6F4',
  fontSize: '16px',
  fontWeight: 400,
  color: '#063846',
  borderRadius: '18px',
  display: 'inline-flex',
  justifyContent: 'center',
  '& svg': {
    marginLeft: '10px'
  },
  '&:hover': {
    backgroundColor: '#1CE6F4'
  },
  [theme.breakpoints.down(1160)]: {
    display: 'none'
  }
}));

const MobileMenuButton = styled(IconButton)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down(1160)]: {
    display: 'flex'
  }
}));

const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  marginTop: '24px',
  paddingBottom: '20px'
}))
