import { useEffect } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Link from '@mui/material/Link'
import { Container } from 'src/components/Container'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { styled } from '@mui/system'
import AOS from 'aos'
import 'aos/dist/aos.css'

const productData = [
  {
    id: 1,
    title: 'Mimik Vaults',
    content: 'Retain full custody and transparency of your funds; Invest any amount; Add or exit at any time',
    cta: {
      label: 'Explore Vaults',
      url: 'https://beta.mimik.finance/strategy'
    }
  },
  {
    id: 2,
    title: 'Mimik Traders',
    content: 'Create a vault; Start trading with access to both spot and derivatives markets and deep liquidity; Earn fees by generating return',
    cta: {
      label: 'Create a Vault',
      url: '#'
    }
  }
]

const OurProducts = () => {
  return (
    <Container>
      <OurProductsContainer component='section' id='products'>
        <SimplifyTitle>Our Products</SimplifyTitle>

        <ProductCardContainer>
          {productData.map((simplify) => (
            <ProductCard
              id={simplify.id}
              title={simplify.title}
              content={simplify.content}
              key={simplify.id}
              cta={simplify.cta}
            />
          ))}
        </ProductCardContainer>
      </OurProductsContainer>
    </Container>
  )
}

export default OurProducts

const OurProductsContainer = styled(Box)(({ theme }) => ({
  height: '100svh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#EBFFFF',
  gap: '4px',
  [theme.breakpoints.down(840)]: {
    alignItems: 'center',
    marginTop: '40px'
  },
  [theme.breakpoints.down(768)]: {
    gap: '4px',
    height: 'auto'
  }
}))

const SimplifyTitle = styled(Box)(({ theme }) => ({
  fontSize: '48px',
  fontWeight: 700,
  textAlign: 'center',
  width: '100%',
  [theme.breakpoints.down(768)]: {
    fontSize: '42px'
  }
}))

interface ProductCardProps {
  title: string
  content: string
  id: number
  cta: {
    label: string,
    url: string
  }
}

const ProductCard = (props: ProductCardProps) => {
  const { title, content, id, cta } = props
  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])
  return (
    <Stack data-aos="fade-up" data-aos-delay={100 * id}>
      <ProductCardWrapper>
        <Box p={4}>
          <ProductCardTitle className="Simplify-card-title">
            {title}
          </ProductCardTitle>
          <SimplifyCardContent>{content}</SimplifyCardContent>
        </Box>
        <ProductCardFooter>
          <CtaWrapper px={4} py={2}>
            <CtaButton href={cta.url}>
              {cta.label}
              <ArrowForwardIcon fontSize="small" />
            </CtaButton>
          </CtaWrapper>
        </ProductCardFooter>
      </ProductCardWrapper>
    </Stack>
  )
}

const ProductCardContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  justifyContent: 'center',
  gap: '30px',
  marginTop: '60px',
  [theme.breakpoints.down(840)]: {
    gridTemplateColumns: 'repeat(1, 1fr)'
  },
  [theme.breakpoints.down(768)]: {
    marginTop: '30px'
  }
}))

const ProductCardWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRadius: '12px',
  border: '2px solid #0A7F94',
  transition: '.3s ease-in-out 0s',
  '&:hover': {
    transform: 'scale(1.05)'
  },
  [theme.breakpoints.down(768)]: {
    gap: '24px'
  }
}))

const ProductCardTitle = styled(Box)(({ theme }) => ({
  fontSize: '24px',
  fontWeight: '700',
  cursor: 'pointer',
  color: '#EBFFFF',
  marginBottom: '16px',
  textDecoration: 'none',
  [theme.breakpoints.down(540)]: {
    fontSize: '20px'
  }
}))

const SimplifyCardContent = styled(Box)(() => ({
  fontSize: '16px',
  fontWeight: 300,
  color: '#CEFEFF'
}))

const CtaWrapper = styled(Box)(() => ({
  background: 'linear-gradient(90deg, #03A0B7 0%, #0A7F94 100%)'
}))

const CtaButton = styled(Link)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  textDecoration: 'none'
}))

const ProductCardFooter = styled(Box)(() => ({
  width: '100%',
  overflow: 'hidden',
  borderRadius: '0px 0px 8px 8px'
}))