import { useEffect, useState } from 'react'
import { Box, Stack } from '@mui/material'
import { styled } from '@mui/system'
import { RightLightImg, PlatformImg1, PlatformImg2 } from 'src/config/images'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import CheckIcon from '@mui/icons-material/Check'
import AOS from 'aos'
import { Container } from 'src/components/Container'
import { useViewport } from 'src/context/ViewportContext'

interface PlatformData {
  key: number;
  content: string;
}

const onePlatformUserData: PlatformData[] = [
  {
    key: 0,
    content: 'Execute both long and short trades, optimizing opportunities for profit and risk management.'
  },
  {
    key: 1,
    content: "Deposit any amount in the vault"
  },
  {
    key: 2,
    content: "Add more funds or exit at any time"
  }
]

const onePlatformTraderData: PlatformData[] = [
  {
    key: 0,
    content: 'Select the name and deploy the vault'
  },
  {
    key: 1,
    content: "Experience seamless trading across both spot and perpetual markets"
  },
  {
    key: 2,
    content: "Generate returns and earn fees"
  }
]

const Tabs = ({ activeTab, setActiveTab }: { activeTab: string, setActiveTab: (x: string) => void }) => {
  return (
    <Box sx={{ margin: '0 auto 60px' }}>
      <Stack
        flexDirection='row'
        sx={{
          background: '#063846',
          border: '1px solid #145465',
          boxShadow: '0 3px 15px 0 #00C8D9BF'
        }}
      >
        <Tab className={activeTab === 'user' ? 'active' : ''} onClick={() => setActiveTab('user')}>
          User
        </Tab>
        <Tab className={activeTab === 'trader' ? 'active' : ''} onClick={() => setActiveTab('trader')}>
          Trader
        </Tab>
      </Stack>
    </Box>
  )
}

const Img = ({ imgSrc, alt }: { imgSrc: string, alt: string }) => {
  return (
    <Box sx={{ width: 320 }}>
      <Stack direction='row' justifyContent='center'>
        <img src={imgSrc} style={{ maxWidth: 200 }} alt={alt} />
      </Stack>
    </Box>
  )
}

interface OnePlatformRenderProps {
  onePlatformData: PlatformData[],
   title: string,
   isLtr: boolean,
  platformImg: string,
  ctaButtonText: string,
  ctaButtonLink?: string,
}

const OnePlatformRender = ({ onePlatformData, title, isLtr, platformImg, ctaButtonText, ctaButtonLink }: OnePlatformRenderProps) => {
  const { isMobile } = useViewport()

  return (
    <OnePlatformCard>
      <Stack
        direction={{ xs: 'column', md: isLtr ? 'row' : 'row-reverse' }}
        alignItems={{ xs: 'center', md: 'end' }}
        justifyContent='center'
        spacing={2}
      >
        <Box pl={4} sx={{ borderLeft: '2px solid #1CE6F4', position: 'relative' }}>
          <TitleIconWrapper>
            <KeyboardDoubleArrowDownIcon sx={{ color: '#063846' }} />
          </TitleIconWrapper>
          <OnePlatformTitle>
            {title}
          </OnePlatformTitle>

          <Stack spacing={3}>
            {onePlatformData.map((data) => (
              <Stack key={data.key} direction='row' alignItems='center'>
                <Box mr={3}>
                  <ContentIconWrapper>
                    <CheckIcon sx={{ color: '#063846' }} fontSize='small' />
                  </ContentIconWrapper>
                </Box>
                <OnePlatformDescription>
                  {data.content}
                </OnePlatformDescription>
              </Stack>
            ))}
          </Stack>
        </Box>
        {!isMobile && <Img imgSrc={platformImg} alt='PlatformImg1 Vaults' />}
      </Stack>
      <a
        href={ctaButtonLink ?? '#'} target="_blank" rel="noopenner noreferrer"
        style={{
          textDecoration: 'none',
          margin: '0 auto',
          width: 'fit-content'
        }}>
        <ExploreButton>
          {ctaButtonText}
        </ExploreButton>
      </a>
    </OnePlatformCard>
  )
}

const OnePlatform = () => {
  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])

  const [activeTab, setActiveTab] = useState<string>('user')

  return (
    <Container>
      <OnePlatformContainer component='section'>
        <OnePlatformBgBox>
          <BgImg src={RightLightImg} alt='bg-img' />
        </OnePlatformBgBox>
        <Stack direction='column' justifyContent='center'>
          <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
          {activeTab === 'user' ? (
            <OnePlatformRender
              platformImg={PlatformImg1}
              onePlatformData={onePlatformUserData}
              title='Vault Investment Process'
              isLtr={true}
              ctaButtonText='Explore Vaults'
              ctaButtonLink='https://beta.mimik.finance/'
            />
          ) : (
            <OnePlatformRender
              platformImg={PlatformImg2}
              onePlatformData={onePlatformTraderData}
              title='Vault Creation Process'
              isLtr={false}
              ctaButtonText='Create a Vault'
            />
          )}
        </Stack >
      </OnePlatformContainer>
    </Container>
  )
}

export default OnePlatform

const OnePlatformBgBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100vw',
  height: '100%',
  overflowX: 'clip',
  pointerEvents: 'none',
  userSelect: 'none'
}))

const OnePlatformContainer = styled(Box)(({ theme }) => ({
  height: '100svh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  [theme.breakpoints.down(768)]: {
    height: 'auto'
  }
}))

const OnePlatformCard = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  width: '100%',
  maxWidth: '1020px',
  gap: '40px',
  padding: '70px 50px',
  borderRadius: '24px',
  background: 'linear-gradient(80.42deg, rgba(28, 230, 244, 0.2) 25.25%, rgba(18, 102, 120, 0.2) 98.05%)',
  zIndex: 1,
  [theme.breakpoints.down(840)]: {
    alignItems: 'center',
    marginTop: '40px'
  },
  [theme.breakpoints.down(768)]: {
    gap: '30px',
    padding: '30px',
    maxWidth: '84%'
  }
}))

const OnePlatformTitle = styled(Box)(({ theme }) => ({
  fontSize: '36px',
  fontWeight: '700',
  marginBottom: 48,
  color: 'rgba(255, 255, 255, 1)',
  [theme.breakpoints.down(768)]: {
    fontSize: '22px'
  }
}))

const OnePlatformDescription = styled(Box)(({ theme }) => ({
  fontSize: 20,
  lineHeight: '36px',
  fontWeight: 400,
  color: 'rgba(255, 255, 255, 0.8)',
  [theme.breakpoints.down(768)]: {
    fontSize: 18,
    padding: '0'
  }
}))

const BgImg = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  right: '0',
  transform: 'translateY(-50%)',
  zIndex: -1,
  [theme.breakpoints.down(768)]: {
    display: 'none'
  }
}))

const Tab = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  padding: '8px 96px',
  borderRadius: 6,
  fontSize: 16,
  fontWeight: 600,
  color: '#03A0B7',

  '&.active': {
    color: '#063846',
    backgroundColor: '#1CE6F4'
  },

  [theme.breakpoints.down(540)]: {
    padding: '8px 44px'
  }
}))

const TitleIconWrapper = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  width: 50,
  height: 50,
  position: 'absolute',
  top: -15,
  left: -35,
  border: '10px solid #0c2f32',
  borderRadius: '50%',
  background: '#1CE6F4',
  [theme.breakpoints.down(540)]: {
    width: 40,
    height: 40,
    left: -30
  }
}))

const ContentIconWrapper = styled(Stack)(() => ({
  width: 25,
  height: 25,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  background: '#1CE6F4'
}))

const ExploreButton = styled(Box)(() => ({
  padding: '8px 40px',
  textAlign: 'center',
  fontSize: 18,
  fontWeight: 600,
  borderRadius: 12,
  color: '#fff',
  backgroundColor: '#00c8d9',
  transition: '.3s ease-in-out 0s',
  '&:hover': {
    transform: 'scale(1.05)',
    backgroundColor: '#0090d1'
  }
}))
