import { Suspense } from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';

import { PUBLIC_ROUTES } from './config/routes';

import { AppThemeProvider } from './Provider';
import { Layout } from './layouts/layout';

import { Home } from './pages/home'
import { Community } from './pages/community'
import { Security } from './pages/security'
import { FAQs } from './pages/faqs'
import { AboutUs } from './pages/about'
import { Terms } from './pages/Terms'
import { Service } from './pages/service'
import { Privacy } from './pages/privacy'
import { Docs } from './pages/docs'
import { Contracts } from './pages/contracts'
import 'aos/dist/aos.css'

function App() {
  return (
    <Suspense fallback={<>Loading</>}>
      <Router>
        <AppThemeProvider>
          <Layout>
            <Routes>
              <Route path={PUBLIC_ROUTES.default} element={<Home />} />
              <Route path={PUBLIC_ROUTES.home} element={<Home />} />
              <Route path={PUBLIC_ROUTES.community} element={<Community />} />
              <Route path={PUBLIC_ROUTES.security} element={<Security />} />
              <Route path={PUBLIC_ROUTES.faqs} element={<FAQs />} />
              <Route path={PUBLIC_ROUTES.terms} element={<Terms />} />
              <Route path={PUBLIC_ROUTES.about} element={<AboutUs />} />
              <Route path={PUBLIC_ROUTES.service} element={<Service />} />
              <Route path={PUBLIC_ROUTES.privacy} element={<Privacy />} />
              <Route path={PUBLIC_ROUTES.docs} element={<Docs />} />
              <Route path={PUBLIC_ROUTES.contracts} element={<Contracts />} />
            </Routes>
          </Layout>
        </AppThemeProvider>
      </Router>
    </Suspense>
  );
}

export default App;
