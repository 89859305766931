export const PUBLIC_ROUTES = {
  default: '/',
  home: '/home',
  community: '/community',
  security: '/security',
  faqs: '/faqs',
  about: '/about-us',
  terms: '/terms-and-conditions',
  service: '/service-agreement',
  privacy: '/privacy-policy',
  docs: '/docs',
  contracts: '/contracts',
  quest: '/quest',
  error404: '/error404'
}

export const PRIVATE_ROUTES = {}
