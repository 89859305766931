import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { Mission } from './mission';
import { LeaderShip } from './leadership';
import { Team } from './team';
import { Container } from 'src/components/Container';

export const AboutUs = () => {
  return (
    <Container>
      <AboutUsContainer mb={24}>
        <Mission />
        <LeaderShip />
        <Team />
      </AboutUsContainer>
    </Container>
  );
};

const AboutUsContainer = styled(Box)(({ theme }) => ({
  maxWidth: '1310px',
  margin: '0 auto',
  paddingTop: '60px',
  display: 'flex',
  flexDirection: 'column',
  gap: '80px',
  color: '#EBFFFF',
  alignItems: 'center'
}));
