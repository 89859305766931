import { Box, Button, Dialog, IconButton, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { KunjiLogoPng, OrnHeroBg } from 'src/config/images';
import { Close } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface NavModalProps {
  isState: boolean;
  setState: (value: boolean) => void;
}

export const NavModal = (props: NavModalProps) => {
  const { isState, setState } = props;
  const handleClose = () => {
    setState(false);
  };
  const navigate = useNavigate();
  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { backgroundImage: 'none' } }}
      fullScreen
      open={isState}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Img src={OrnHeroBg} alt="bg-img" />
      <DialogHeader>
        <KunjiLogo src={KunjiLogoPng} alt="Kunji-logo" onClick={() => navigate('/')} />
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogHeader>
      <DialogContent>
        <a
          href="/#products"
          style={{ textDecoration: 'none' }}
        >
          <TabBar>Products</TabBar>
        </a>
        <TabBar active={location.pathname === '/about-us' ? 1 : 0} onClick={() => navigate('/about-us')}>
          About Us
        </TabBar>
        <TabBar active={location.pathname === '/community' ? 1 : 0} onClick={() => navigate('/community')}>
          Community
        </TabBar>
        <TabBar active={location.pathname === '/docs' ? 1 : 0} onClick={() => navigate('/docs')}>
          Docs
        </TabBar>
        <a
          href='https://beta.mimik.finance/'
          target='_blank'
          style={{ textDecoration: 'none' }}
          rel="noopenner noreferrer"
        >
          <LaunchBetaButton>Launch Dapp</LaunchBetaButton>
        </a>
      </DialogContent>
    </Dialog>
  );
};

const DialogHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '30px'
}));

const KunjiLogo = styled('img')(({ theme }) => ({
  width: '125px',
  height: 'auto',
  cursor: 'pointer'
}));

const Img = styled('img')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: '0',
  left: '0',
  borderRadius: '50%',
  zIndex: 0
}));

const DialogContent = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '40px',
  zIndex: 1,
  paddingTop: '40px'
}));

const TabBar = styled(Box)<{ active?: number }>(({ theme, active }) => ({
  color: active === 1 ? '#FFF' : '#667085',
  fontSize: '24px',
  cursor: 'pointer',
  fontWeight: active === 1 ? '600' : '300',
  '&:hover': {
    color: '#FFFFFF'
  }
}));

const LaunchBetaButton = styled(Button)(({ theme }) => ({
  textDecoration: 'capitalize',
  padding: '16px 32px',
  backgroundColor: '#1CE6F4',
  fontSize: '18px',
  color: '#063846',
  borderRadius: '18px',
  marginTop: '40px',
  '&:hover': {
    backgroundColor: '#1CE6F4'
  }
}));
