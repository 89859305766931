import { Box, Stack } from '@mui/material'
import { styled } from '@mui/system'
import {
  AnuragImg,
  HungImg,
  SantoImg,
  DiegoImg,
  MaksimImg,
  NanjeshImg,
  LinkedinSvg,
  PranjalImg,
  PratikImg,
  ShreyImg,
  TakeshiImg,
  VaibhavImg
} from 'src/config/images'

export const Team = () => {
  const members = [
    {
      id: 1,
      name: 'Pratik',
      role: 'Product Manager',
      linkedin: 'https://www.linkedin.com/in/pratik--arora/',
      avatar: PratikImg
    },
    {
      id: 2,
      name: 'Shrey',
      role: 'Developer',
      linkedin: 'https://www.linkedin.com/in/shreypaharia/',
      avatar: ShreyImg
    },
    {
      id: 3,
      name: 'Vaibhav',
      role: 'Developer',
      avatar: VaibhavImg
    },
    {
      id: 4,
      name: 'Pranjal',
      role: 'Developer',
      linkedin: 'https://www.linkedin.com/in/pranjalsoni',
      avatar: PranjalImg
    },
    {
      id: 5,
      name: 'Maksim',
      role: 'Developer (Protofire)',
      avatar: MaksimImg
    },
    {
      id: 6,
      name: 'Diego',
      role: 'Developer (Protofire)',
      avatar: DiegoImg
    },
    {
      id: 7,
      name: 'Hung',
      role: 'Developer',
      avatar: HungImg
    },
    {
      id: 8,
      name: 'Takeshi',
      role: 'Developer',
      linkedin: 'https://www.linkedin.com/in/takeshi-ishikawa-ab3b02210',
      avatar: TakeshiImg
    },
    {
      id: 9,
      name: 'Santo',
      role: 'Developer',
      linkedin: 'https://www.linkedin.com/in/santo-sinar-pandean-129562152/',
      avatar: SantoImg
    },
    {
      id: 13,
      name: 'Nanjesh',
      role: 'Operations',
      linkedin: 'https://www.linkedin.com/in/nanjesh-patel-8045a21b8/',
      avatar: NanjeshImg
    },
    {
      id: 14,
      name: 'Anurag',
      role: 'Communication',
      avatar: AnuragImg
    }
  ]
  const sortedMembers = members.sort((a, b) => a.name < b.name ? -1 : 1)

  return (
    <TeamContainer>
      <TeamTitle>Team</TeamTitle>
      <TeamMemberContainer>
        {sortedMembers.map((member) => (
          <TeamMember
            key={member.id}
            avatar={member.avatar}
            name={member.name}
            role={member.role}
            linkedin={member.linkedin}
          />
        ))}
      </TeamMemberContainer>
    </TeamContainer>
  )
}

const TeamContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
  position: 'relative'
}))

const TeamTitle = styled(Box)(({ theme }) => ({
  fontSize: '54px',
  fontWeight: '600',
  [theme.breakpoints.down(640)]: {
    fontSize: '45px'
  }
}))

const TeamMemberContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(5, 1fr)',
  gap: '20px',
  marginBottom: '60px',
  [theme.breakpoints.down(1076)]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '20px'
  }
}))

interface TeamMemberProps {
  avatar: string
  name: string
  role: string
  linkedin?: string
}

const TeamMember = (props: TeamMemberProps) => {
  const { avatar, name, role, linkedin } = props
  return (
    <TeamMemberWrapper>
      <TeamMemberAvatar src={avatar} alt="team-member" />
      <TeamMemberInfo>
        <TeamMemberName>
          {name}
          <LinkedIn href={linkedin} target="_blank" rel="noopenner noreferrer">
            <img src={LinkedinSvg} alt="linkedin-svg" />
          </LinkedIn>
        </TeamMemberName>
        <TeamMemberRole>{role}</TeamMemberRole>
      </TeamMemberInfo>
    </TeamMemberWrapper>
  )
}

const TeamMemberWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '30px 20px',
  border: '1px solid #1CE6F4',
  borderRadius: '20px',
  gap: '20px'
}))

const TeamMemberAvatar = styled('img')(({ theme }) => ({
  borderRadius: '50%',
  width: '80px',
  height: '80px',
  objectFit: 'cover',
  [theme.breakpoints.down(540)]: {
    width: '120px',
    height: '120px'
  }
}))

const TeamMemberInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '7px'
}))

const TeamMemberName = styled(Stack)(({ theme }) => ({
  fontSize: '24px',
  color: '#F0F4FD',
  fontWeight: '600',
  textAlign: 'center',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  [theme.breakpoints.down(768)]: {
    fontSize: '20px'
  }
}))

const TeamMemberRole = styled(Box)(({ theme }) => ({
  fontSize: '18px',
  color: '#9c9c9c',
  textAlign: 'center',
  [theme.breakpoints.down(768)]: {
    fontSize: '14px'
  }
}))

const LinkedIn = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  borderRadius: '50%',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  '& img': {
    width: '25px',
    height: '25px'
  }
}));
