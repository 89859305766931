import { useEffect } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';

export const Privacy = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);
  return (
    <PrivacyContainer mb={24}>
      <PrivacyTitle>Privacy Policy</PrivacyTitle>
      <PrivacyContent>
        <strong>
          Last updated: 04/08/2023
          <br />‍
        </strong>
        <br />
        We, Kunji Dao Foundation (“Foundation”, “we”, “us”, “our”) are the owner of the website www.Mimik.finance (the
        “Website”); a web-based application and mobile application namely “Mimik Finance” together referred to as the
        “Platform” or "Mimik Finance".
        <br />
        <br />
        Mimik Finance facilitates users (hereinafter referred to as “You”, “Your” or “User”) to invest in Crypto Assets,
        Cryptocurrencies and other digital assets (collectively “Virtual Digital Assets”). Mimik Finance allows
        investors in Virtual Digital Asset classes to invest in Strategies that are actively managed by asset managers
        based on their risk appetite, time horizon and other such preferences offered by the Foundation through its
        Platform (“Services”).
        <br />
        <br />
        Please note for the purposes of this Privacy Policy the terms “You”, “Your” or “User” shall also include DAMs
        registered with Mimik Finance. <br />
        <br />
        We respect data privacy rights and are committed to protecting personal information collected on Mimik Finance.
        This privacy policy (“Privacy Policy”) sets forth how we collect, use, and protect the personal information
        collected on Mimik Finance. <br />
        <br />
        PLEASE READ THIS PRIVACY POLICY CAREFULLY. THE PRIVACY POLICY IS TO BE READ ALONG WITH MIMIK FINANCE TERMS OF
        USE, INVESTOR AGREEMENT ANY OTHER APPLICABLE TERMS ANDCONDITIONS MADE AVAILABLE ON THE WEBSITE OR PLATFORM.
        <br />
        <br />
        BY CONTINUING TO ACCESS MIMIK FINANCE, USE THE SERVICES, AND VOLUNTARILY PROVIDING PERSONAL INFORMATION, YOU
        CONSENT TO OUR USE OF YOUR PERSONAL INFORMATION IN ACCORDANCE WITH THE TERMS OF THIS PRIVACY POLICY. IF YOU DO
        NOT AGREE TO THIS PRIVACY POLICY, YOU MAY WITHDRAW YOUR CONSENT OR ALTERNATIVELY CHOOSE NOT TO PROVIDE US WITH
        ANY PERSONAL INFORMATION AND NOT ACCESS MIMIK FINANCE OR AVAIL THE SERVICES. SUCH AN INTIMATION TO WITHDRAW YOUR
        CONSENT CAN BE PROVIDED BY EMAIL VIA privacy@mimik.finance .<br />
        <br />
        IF YOU ARE ACCESSING MIMIK FINANCE OR USING THE SERVICES ON BEHALF OF A THIRD PARTY, YOUR REPRESENT THAT YOU
        HAVE THE AUTHORITY TO USE MIMIK FINANCE OR AVAIL SERVICES AND TO BIND SUCH THIRD-PARTY TO THE TERMS AND
        CONDITIONS OF THIS PRIVACY POLICY AND, IN SUCHAN EVENT YOUR USE OF THE PLATFORM AND OR THE SERVICES SHALL REFER
        TO USE BY SUCH THIRD PARTY. IF YOU DO NOT HAVE SUCH AN AUTHORITY (TO PROVIDE ANY PERSONAL INFORMATION OF A THIRD
        PARTY) OR DO NOT AGREE TO THE TERMS OF THIS PRIVACY POLICY, THEN YOU SHOULD REFRAIN FROM USING THE SERVICES.
        <br />
        <br />
        THIS PRIVACY POLICY IS TO BE READ IN CONJUNCTION WITH ANY TERMS AND CONDITIONS OR AGREEMENTS PROVIDED ON THE
        PLATFORM.
        <br />
        <br />
        <strong>1. TYPES OF INFORMATION COLLECTED</strong>
        <br />
        While registering with Mimik Finance, you are required to provide us with personal information such as email
        address and wallet address.
        <br />
        We collect Personal Information such as name, email address, or any other information you voluntarily provide
        through our contact us page or through the chat function or when you otherwise engage with us through any modes
        of communication. For the sake of brevity, the aforementioned information shall be referred to as “Personal
        Information”.
        <br />
        We may also collect some technical information including but not limited to internet protocol (IP)address, your
        login data, browser type and version, time zone setting and location, browser plug-intypes and versions,
        operating system and platform, and other technology on the devices you use toaccess Mimik Finance.
        <br />
        <strong>Transactional Information:</strong> We collect information about the investments made on Mimik
        Finance,such as frequency, product details, purchase price, and the date and location of the transaction.
        <br />
        <strong>Website and Social media accounts: </strong>We may also collect, store and use your Personal
        Informationwhen you (1) access our social networking handles operated by us on Facebook, Twitter,
        and/orLinkedIn, Discord or other similar communication and social media platforms (2) share our blogs and(3)
        comment/ provide feedback/testimonials on such social networking handles.
        <br />
        <strong>Accuracy of information:</strong>
        User undertakes that he shall be solely responsible for the accuracy,correctness, or truthfulness of the
        Personal Information shared/uploaded on Mimik Finance whetherof its own or any third party. In the event the
        User is sharing any Personal Information and User onbehalf of a third person, the User represents and warrants
        that he has the necessary authority toshare such Personal Information with the Foundation, obtained a written
        consent from such thirdparty and the Foundation shall not be responsible for verifying the same. The User
        understands andacknowledges that such Personal Information shall be subject to the terms and conditions of
        thisPrivacy Policy.
        <br />‍<br />
        <strong>
          2. TRACKING TECHNOLOGIES
          <br />
        </strong>
        We use tracking technologies, analytical tools and cookies to improve our Platform and experience of our Users.{' '}
        <br />
        <strong>Cookies:</strong> We use cookies and similar tracking technologies to track user traffic patterns and
        hold certain registration information. Tracking technologies also used are beacons, tags and scripts tocollect
        and track information and to improve and analyze our service. If you wish to not have the information these
        technologies collect, used for the purpose of serving you targeted ads the Help menu on the menu bar of most
        browsers will tell you how to prevent your browser from accepting new cookies, how to disable cookies altogether
        etc.
        <br />
        <strong>Types of Cookies used: </strong>We use the following types of Cookies:
        <br />
        i. Strictly necessary cookies
        <br />
        ii. Analytical/performance cookies.
        <br />
        iii. Targeting cookies.
        <br />
        <strong>We use cookies to</strong>:<br />
        i. improve the user experience on Mimik Finance,
        <br />
        ii. understand the usage based on the geographical area,
        <br />
        iii. understand the usage based on the demographics,
        <br />
        iv. understand and get usage patterns of the users,
        <br />
        The cookies will collect following information:
        <br />
        i. Clicks and scroll on the Website and Platform;
        <br />
        ii. Visitor’s operating system, browser information, CPU, GPU; andiii. Information of Service Provider. Will
        disabling the cookies allow users to use Mimik Finance?We will not restrict the usage of Mimik Finance if the
        cookies are disabled.
        <br />
        <strong>
          3. DISCLOSURE OF USER’S PERSONAL DATA
          <br />
        </strong>
        We shall collect Personal Information of the User for the purpose of KYC and meeting our regulatory obligations.
        If requested by the User, we will not disclose the identity of the User on Mimik Finance or to other Users.
        However, we may have to disclose the Personal Information of the User inaccordance with clause 6 on Disclosures.
        <br />
        <strong>
          4. USE OF PERSONAL INFORMATION
          <br />
        </strong>
        We shall use the Personal Information collected from the Website and Platform as follows:
        <br />
        <strong>
          A. Personal Information provided by visitors of Website:
          <br />
        </strong>
        i. To respond to enquiries pertaining to our Services and product offerings.
        <br />
        ii. To provide information about any new offerings/and or our periodic newsletter.
        <br />
        iii. Monitor and analyze trends, usage, and activities in connection with our Website and Platform.
        <br />
        iv. To comply with our legal or statutory obligations. <br />
        <strong>
          B. Personal Information provided by Users of Mimik Finance:
          <br />
        </strong>
        i. To complete the registration/subscription formalities and to create an account for the User.
        <br />
        ii. Personalize and improve your experience on our Platform.
        <br />
        iii. To provide the User with the Services and to assist the User in the event the User needs any additional
        support.
        <br />
        iv. To provide information about any new offerings/and or our periodic newsletter.
        <br />
        v. To assist User with technical difficulties that may arise in relation to User’s use and access ofPlatform.
        <br />
        vi. Send you technical support, security alerts, and support and administrative messages
        <br />
        vii. for creation or development of business intelligence or data analytics in relation to the Services provided
        on Mimik Finance.
        <br />
        viii. Monitor and analyze trends, usage, and activities in connection with our Platform.
        <br />
        ix. To comply with our legal or statutory obligations.
        <br />
        x. To improve service offerings through personalization and segmentation.
        <br />‍<br />
        <strong>
          5. ANONYMIZED DATA
          <br />
        </strong>
        <br />
        We may also use your Personal Information collected to create aggregate anonymized data. We shall ensure that
        such anonymized data will not be directly identifiable to you or to the Personal Information shared with us. We
        may use this anonymized data for any purpose including but not limited to conduct research, analytical purposes,
        and to improve our Services. By using the Services and/or visiting our Platform, you provide us the right to use
        your Personal Information to create anonymized data and use it for our business purposes.
        <br />
        <br />
        <strong>
          6. DISCLOSURES
          <br />
        </strong>
        <br />
        We do not sell, rent, distribute, lease or otherwise provide your Personal Information to thirdparties, without
        your prior consent. Accordingly, you expressly give your free consent to us todisclose or share your Personal
        Information in the following cases:
        <br />
        <strong>i. Administrators: </strong>We may provide access to User’s Personal information to any of our
        authorized administrators for an internal business purpose, who shall be under confidentiality obligations
        towards the same.
        <br />
        <strong>ii. Service Providers: </strong>We may share your Personal Information with the service providers who
        work with us in connection with operating Mimik Finance and/ or providing the Services. We may share your
        Personal Information with service providers that provide the technical infrastructure for Kinji Finance. All
        such service provider is subject to stringent confidentiality restrictions consistent with this Privacy Policy.
        <br />
        <strong>iii. Merger or Acquisition:</strong>
        We may transfer User’s Personal Information if we are acquired by or we acquire or merge with another company or
        transfer a part of our business, including Mimik Finance, to a third party. Any third party or resultant entity
        that receives the User’s Personal Information pursuant to a merger, demerger, amalgamation, reconstruction,
        change in control or business transfer shall have the right to continue to use User’s Personal Information. In
        the event of such a sale or transfer, we may notify the Users. iv. Legal and Regulatory Authorities: We may in
        order to comply with our legal obligations/court orders/ requests by Govt. authorities share Personal
        Information with legal andregulatory authorities.
        <br />‍<br />
        <strong>
          7. DATA RETENTION
          <br />
        </strong>
        <br />
        We endeavour to only collect such Personal Information that is necessary for the purposes indicaedhere, and to
        retain such data for no longer than is necessary for such purposes. The length of time personal Information is
        retained, and criteria for determining that time, are dependent on the nature of the Personal Information and
        the purpose for which it was provided/ collected.
        <br />
        <br />
        <strong>
          8. SECURITY
          <br />
        </strong>
        <br />
        User’s Personal Information is stored on third-party cloud servers. Although we provide appropriate firewalls
        and protections, we cannot warrant the security of any Personal Information transmitted as our systems are not
        hackproof. Data pilferage due to unauthorized hacking, virus attacks, technical issues is possible, and we will
        take necessary action to mitigate any incidents.The user is responsible for all the actions that take place
        under the User Account. If User chooses to share User Account details and password or any Personal Information
        with third parties, the User is solely responsible for the same. If User loses control of the User Account, User
        may lose substantial control over its Personal Information and may be subject to legally binding actions. It is
        User’s responsibility to keep User’s password confidential and secure.
        <br />
        <br />
        <strong>
          9. CHOICE AND OPT-OUT
          <br />
        </strong>
        <br />
        We may send you communications including but not limited to (a) notices about your use of our platform and
        Services, including those concerning violations of use, (b) updates, (c) promotional information regarding the
        Services, and (d) newsletters. You may opt out of receiving promotional emails and newsletters from us by
        following the unsubscribe instructions provided in those emails. Alternatively, you can opt-out, at any time, by
        emailing privacy@mimik.finance with your s.
        <br />
        <br />
        <strong>
          10. CHILDREN’S PRIVACY
          <br />
        </strong>
        <br />
        If we become aware that we have collected Personal Information from children without verification of parental
        consent, we take steps to remove such Personal Information from our servers. If any parent/ guardian becomes
        aware that a minor/child has provided their Personal Information to us through the Platform, such parent/
        guardian should inform us and we will take steps to remove that personal Information from our servers specific
        request
        <br />‍<br />
        <strong>
          11. LIMITATION OF LIABILITY
          <br />
        </strong>
        <br />
        USER EXPRESSLY UNDERSTANDS AND AGREES THAT THE FOUNDATION SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT,
        INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR
        LOSS OF PROFITS, GOODWILL, USE, DATA, INFORMATION, DETAILS OR OTHER INTANGIBLE LOSSES (EVEN IF THE FOUNDATION
        HAS BEENADVISED OF THE POSSIBILITY OF SUCH DAMAGES), ARISING OUT OF THIS PRIVACY POLICY. THE FOREGOING
        LIMITATION OF LIABILITY SHALL APPLY NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY
        HEREIN.
        <br />
        <br />
        <strong>
          12. INDEMNIFICATION
          <br />
        </strong>
        <br />
        User agrees to indemnify us, our subsidiaries, affiliates, officers, agents, and employees (each, an“Indemnified
        Party”) and hold the Indemnified Party harmless from and against any claims and demand, including reasonable
        attorneys&amp;#39; fees, made by any third party due to or arising out of or relating to: (i) accuracy and
        correctness of Personal Information that User submits or shares through Mimik Finance and (ii) User’s and its
        permitted user’s violation of this Privacy Policy and applicable laws.
        <br />
        <br />
        <strong>
          13. CHANGES TO THIS POLICY
          <br />
        </strong>
        <br />
        We may update this Privacy Policy from time to time and you are encouraged to check this Privacy Policy on a
        regular basis to be aware of the changes made to it. Any subsequent change will be notified to the user and will
        be assumed accepted by the user unless they specifically inform us by sending a mail to privacy@mimik.finance
        with “reject privacy policy” in the subject line within 15 days of first notification.
        <br />
        <br />
        <strong>
          14. CONTACT US
          <br />
        </strong>
        <br />
        If you have any questions or concerns or grievances regarding this Privacy Policy or would like to withdraw your
        consent, you can email our grievance officer privacy@mimik.finance.
      </PrivacyContent>
    </PrivacyContainer>
  );
};

const PrivacyContainer = styled(Box)(({ theme }) => ({
  maxWidth: '668px',
  marginBottom: '72px',
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingBottom: '0',
  paddingTop: '100px'
}));

const PrivacyContent = styled(Box)(({ theme }) => ({
  color: '#d5d4d4',
  textAlign: 'justify',
  marginLeft: '0',
  marginRight: '0',
  paddingLeft: '0',
  fontWeight: '400',
  fontSize: '18px'
}));

const PrivacyTitle = styled(Box)(({ theme }) => ({
  fontSize: '54px',
  textAlign: 'center',
  fontWeight: '700'
}));
