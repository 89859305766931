import { useEffect } from 'react';
import { ArrowOutward } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { Container } from 'src/components/Container'
import { FiGithub } from "react-icons/fi";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { HiMiniChartPie } from "react-icons/hi2";
import { IconType } from 'react-icons';

export const Docs = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);
  const docsData = [
    {
      id: 2,
      title: 'Gitbook',
      content: 'Get access to our complete documentation here.',
      icon: FiGithub,
      link: 'https://mimik.gitbook.io/mimik-finance'
    },
    {
      id: 4,
      title: 'Contracts',
      content: 'Check out our contract addresses here.',
      icon: LiaFileInvoiceDollarSolid,
      link: '/contracts'
    },
    {
      id: 5,
      title: 'Bug Bounty',
      content: 'Coming soon.',
      icon: HiMiniChartPie,
      link: '#'
    }
  ];
  return (
    <Container>
      <DocsContainer mb={{ xs: 10, md: 24 }}>
        <DocsTitle>Docs</DocsTitle>
        <DocsItemContainer>
          {docsData.map((docs) => (
            <DocsItem key={docs.id} title={docs.title} content={docs.content} link={docs.link} icon={docs.icon} />
          ))}
        </DocsItemContainer>
      </DocsContainer>
    </Container>
  );
};

const DocsContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '72px',
  paddingTop: '100px'
}));

const DocsTitle = styled(Box)(() => ({
  fontSize: '54px',
  color: '#EBFFFF',
  fontWeight: '600'
}));

const DocsItemContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  columnGap: '25px',
  rowGap: '72px',
  [theme.breakpoints.down(1024)]: {
    gridTemplateColumns: 'repeat(2, 1fr)'
  },
  [theme.breakpoints.down(840)]: {
    columeGap: '72px'
  },
  [theme.breakpoints.down(640)]: {
    gridTemplateColumns: 'repeat(1, 1fr)'
  }
}));

interface DocsItemProps {
  title: string;
  content: string;
  link: string;
  icon: IconType;
}

const DocsItem = (props: DocsItemProps) => {
  const { title, content, link, icon } = props;
  const IconComp = icon
  return (
    <DocsItemWrapper>
      <ALink href={link} target="_blank" rel="noopenner noreferrer">
        <DocsItemTitle>
          <Stack gap={2} direction='row' alignItems='center'>
            <IconComp />
            {title}
          </Stack>
          <ArrowOutward />
        </DocsItemTitle>
      </ALink>
      <DocsItemContent>{content}</DocsItemContent>
    </DocsItemWrapper>
  );
};

const DocsItemWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px 32px',
  border: '1px solid #145465',
  borderRadius: 12,
  background: '#06384626',
  gap: '10px',
  transition: '.3s ease-in-out 0s',
  '&:hover': {
    transform: 'scale(1.05)'
  }
}));

const DocsItemTitle = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '24px',
  fontWeight: '600',
  color: '#EBFFFF',
  '&:hover': {
    color: '#1be6f4'
  }
}));

const DocsItemContent = styled(Box)(() => ({
  fontSize: '18px',
  color: '#EBFFFF'
}));

const ALink = styled('a')(() => ({
  textDecoration: 'none'
}));
